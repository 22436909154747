import { useEffect, useState } from "react";
import { deletePackageById, getPackages } from "../../helpers/Help/CommomApi";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { CustomTableStyle } from "../../Components/Common/constants";
import { DeleteIcon } from "../../Components/Common/SvgIcons";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Spinner } from "reactstrap";
import { DropdownStyle } from "../NewDashboard/constant";
import LoopIcon from "@mui/icons-material/Loop";
import { useSelector } from "react-redux";

const Package = () => {
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userDetails.userData);

  const userRole = userData?.role;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");

  const fetchPackageData = async () => {
    try {
      const response = await getPackages(query);
      const filteredData =
        statusFilter === "All"
          ? response
          : response.filter((pkg) =>
              statusFilter === "Active" ? pkg.status === 1 : pkg.status === 0
            );
      setData(filteredData);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPackageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, statusFilter]);

  const handleDeleteAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-dark mx-2",
        cancelButton: "btn btn-primary mx-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          handleDelete(id);
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Package has been deleted.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Package is safe :)",
            "error"
          );
        }
      });
  };

  const handleDelete = async (id) => {
    try {
      await deletePackageById(id);
      setData(data.filter((pkg) => pkg.package_id !== id));
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleReset = () => {
    setStatusFilter("All");
    setQuery("");
  };

  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.title,
      sortable: true,
      grow: 1.5,
    },
    {
      name: "Monthly Price",
      selector: (row) => row.price_monthly,
      grow: 1.5,
    },
    {
      name: "Annual Price",
      selector: (row) => row.price_annually,
      grow: 1.5,
    },
    {
      name: "Status",
      selector: (row) => (row.status === 1 ? "Active" : "In-active"),
      grow: 1.5,
    },

    {
      name: <span className="text-center">Actions</span>,
      cell: (row) => (
        <Box
          className="report-table-menu"
          sx={{ gap: "25px", display: "inline-flex" }}
        >
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id={`tooltip-edit-${row.id}`}>Edit</Tooltip>}
            >
              <span
                onClick={() => navigate(`/package/edit/${row.package_id}`)}
                style={{ cursor: "pointer" }}
              >
                <EditOutlinedIcon />
              </span>
            </OverlayTrigger>
          </>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-delete-${row.id}`}>
                {row.isUserAvailableInThisPackage
                  ? "This package is assigned to the user, so it can't be deleted."
                  : "Delete"}
              </Tooltip>
            }
          >
            <span
              className={`${
                userRole === "Super Admin" || row.name !== userRole
                  ? "cursor-pointer"
                  : ""
              }`}
              style={{
                opacity: row.isUserAvailableInThisPackage ? 0.6 : 1,
                cursor: row.isUserAvailableInThisPackage
                  ? "not-allowed"
                  : "pointer",
              }}
              onClick={() => {
                if (!row.isUserAvailableInThisPackage) {
                  handleDeleteAlert(row.package_id);
                }
              }}
            >
              <DeleteIcon />
            </span>
          </OverlayTrigger>
        </Box>
      ),
      center: true,
    },
  ];

  return (
    <>
      <Card className="table-main-card">
        <Box className="table-card-header">
          <Box className="left-side">
            <Box className="search-box">
              <input
                type="text"
                className="form-control"
                placeholder="Search package name"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </Box>
            <FormControl
              fullWidth
              variant="filled"
              sx={{
                width: "150px",
                minWidth: "150px",
              }}
            >
              <Select
                value={statusFilter}
                sx={DropdownStyle}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Status"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="In-active">In-active</MenuItem>
              </Select>
            </FormControl>
            <Button className="btn btn-primary reset-btn" onClick={handleReset}>
              <LoopIcon />
            </Button>
          </Box>

          <Button
            className="btn btn-primary view-btn"
            style={{ minWidth: "162px" }}
            onClick={() => navigate("/package/add")}
          >
            Create Package
          </Button>
        </Box>
        <Card.Body>
          {data?.length <= 0 && isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <Spinner height={100} width={100} />
            </div>
          ) : (
            <DataTable
              fixedHeight="500px"
              pagination
              columns={columns}
              data={data}
              customStyles={CustomTableStyle}
              className="table-design"
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Package;
