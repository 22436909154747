import axios from "axios";
import { API_ROUTES } from "../../Api/ApiRoutes";

const API_BASE_URL = process.env.REACT_APP_BASE_URL; // Replace with your API URL

const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

// Function to get a resource by ID
export const getResourceById = (
  params = "",
  isTicketModule = false,
  tickedId = null
) => {
  return axios.get(
    `department-user/get-department-user?searchParams=${params}&isTicketModule=${isTicketModule}&ticketId=${tickedId}`
  );
};

// SITE STATUS

export const getSiteStatus = (assets_id) => {
  return axios.post("site-data/site-status", { assets_id });
};

// Function to create a new resource
export const createResource = (resourceData) => {
  return apiService.post("/resource", resourceData);
};

// Function to update a resource
export const updateResource = (resourceId, resourceData) => {
  return apiService.put(`/resource/${resourceId}`, resourceData);
};

// Function to delete a resource by ID
export const deleteResource = (resourceId) => {
  return apiService.delete(`/resource/${resourceId}`);
};

// Function to get notification setting
export const getNotificationSetting = () => {
  return axios.get(`/notification/get-notification-setting`);
};

export const updateNotificationSetting = (data) => {
  return axios.put(`/notification/update-notification-setting`, data);
};

// Function to get Roles
export const getRoles = (query) => {
  return axios.get(`role/get-roles?searchParams=${query}`);
};

// Function to delete role
export const deleteRoleById = async (id) => {
  return await axios.put(`/role/delete-role/${id}`);
};

// Function to get users
export const getUserData = () => {
  const userId = localStorage.getItem("user_id");
  return axios.get(API_ROUTES.GET_USERS + `/${userId}`);
};

export const getAllUserData = () => {
  return axios.get(API_ROUTES.GET_USERS);
};

export const getInternalUsersData = (params) => {
  let url =
    API_ROUTES.GET_DEPARTMENT_USERS +
    `?searchParams=${params?.searchParams || ""}&departmentNumber=${
      params?.departmentNumber
    }`;
  return axios.get(url);
};

// WEBSITE GUIDELINE
export const getWebsiteGuideline = () => {
  return axios.get(`${API_ROUTES.GET_WEBSITE_GUIDELINE}?searchParams=${""}`);
};

// BRANDING GUIDELINE
export const getBrandingGuideline = () => {
  return axios.get(`${API_ROUTES.GET_BRANDING_GUIDELINE}?searchParams=${""}`);
};

// ONLINE ASSETS
export const getAllOnlineAssets = () => {
  return axios.post(`online-assets/all-online-assets-list`);
};

// PERFORMANCE
export const getPerformanceData = (data) => {
  return axios.post(`site-data/performance-data-test`, data);
};

// DASHBOARD MODULE

export const getCompliances = (startDate, endDate) => {
  const params = new URLSearchParams();
  if (startDate) params.append("startDate", startDate);
  if (endDate) params.append("endDate", endDate);
  return axios.get(`compliance/get-compliance`, { params });
};

// Function to post dashboard settings
export const updateDashboardSettings = (userId, settings) => {
  return axios.post("/dashboard/dashboard-settings", {
    user_id: userId,
    ...settings,
  });
};

// Function to get dashboard settings for a specific user
export const fetchDashboardSettings = (userId) => {
  return axios.get(`/dashboard/dashboard-settings/${userId}`);
};

// ---------------------------------------------------------

// Function to update a maintenance status
export const updateMaintenanceTimes = (
  assetsId,
  startMaintenance,
  endMaintenance
) => {
  return axios.put(`/apilogs/update-maintenance/${assetsId}`, {
    start_maintenance: startMaintenance,
    end_maintenance: endMaintenance,
  });
};

// Function to get maintenance times by assets_id
export const getMaintenanceTimes = (assets_id) => {
  return axios.get(`/apilogs/get-maintenance/${assets_id}`);
};

export const cancelMaintenanceTimes = (assets_id) => {
  return axios.post(`/apilogs/cancel-maintenance/${assets_id}`);
};

//function for service data
export const getServices = () => {
  return axios.get("package/get-services");
};

export const addPackage = (finalData) => {
  return axios.post("package/create-package", finalData);
};

// PACKAGE & SERVICES
// export const getPackagesServices = () => {
//   return axios.get(`/package/get-package-services`);
// };

// PACKAGE & SERVICES (fetch all or specific by user_id)
export const getPackagesServices = (userId = null) => {
  if (userId) {
    return axios.post(`/package/get-package-services`, { id: userId });
  } else {
    return axios.post(`/package/get-package-services`);
  }
};

export const getPackages = (query) => {
  return axios.get(`/package/get-packages?searchParams=${query}`);
};

//delete packages
export const deletePackageById = (id) => {
  return axios.put(`/package/delete-package/${id}`);
};

// Analytics Sub Service
export const getAnalyticsServices = () => {
  return axios.get(`/package/get-analytics-services`);
};
// getPackageServicesById
export const getPackageServicesById = (id) => {
  return axios.get(`/package/get-package-services/${id}`);
};

// update the package service
export const updatePackage = (id, finalData) => {
  return axios.post(`package/create-package/${id}`, finalData);
};

// user package limit
export const getUserPackageLimit = (id) => {
  return axios.get(`package/get-package-limit/${id}`);
};

export const getAllPackageList = () => {
  return axios.get(`package/get-packages`);
};

export const getAllAnalyticData = (data) => {
  return axios.post(`asset/analytics-data`, data);
};

export const getUserSessionChartData = (data) => {
  return axios.post("/asset/analytics-total-users", data);
};

export const getAnalyticsChartData = (data) => {
  return axios.post("/asset/analytics-chart-data", data);
};

export const getAnalyticsRealtimeData = (data) => {
  return axios.post(`/site-data/analytics-realtime-data`, data);
};
