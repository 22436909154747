import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  ListItemIcon,
  Skeleton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import common from "../../Components/Common/common";

export const ListSkeleton = ({ listsToRender }) => {
  return (
    <Grid container spacing={3} alignItems="center" padding={"10px"}>
      {Array(listsToRender)
        .fill(1)
        .map((_, index) => (
          <Grid item xs={4} key={index} style={{ height: "90px" }}>
            {" "}
            <div
              style={{
                display: "flex",
                height: "100%", // Parent height, skeleton will adjust to this
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "40px",
              }}
            >
              <Skeleton
                variant="rounded"
                width="100%" // Adjusts to parent width
                height="100%" // Adjusts to parent height
              />
            </div>
          </Grid>
        ))}
    </Grid>
  );
};

const MyPackage = (props) => {
  const plan = props?.myPackage[0];
  const currentUserPackageStatus = props?.currentUserPackageStatus || null;
  const userRole = localStorage.getItem("role");

  const serviceNameArray = ["assets", "users_limits"];

  return (
    <Box sx={{ padding: "20px", borderRadius: "10px" }}>
      <Typography variant="h4" align="center" gutterBottom>
        My Current Plan
      </Typography>

      <Card
        variant="outlined"
        sx={{
          borderRadius: "15px",
          padding: "20px",
          boxShadow: 3,
          transition: "transform 0.3s",
        }}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          {/* This below Typography is not applicable to super admin */}
          {userRole !== "Super Admin" && (
            <Typography
              variant="p"
              align="center"
              sx={{
                fontWeight: "bold",
                mb: 3,
                fontSize: "1rem",
                color: "#333",
              }}
            >
              {currentUserPackageStatus?.["isPackageExpired"]
                ? `Your plan expired ${
                    currentUserPackageStatus?.["package_end_date"] &&
                    `on ${currentUserPackageStatus?.["package_end_date"]}`
                  }`
                : currentUserPackageStatus?.["package_end_date"] && (
                    <>
                      Expired in :{" "}
                      {currentUserPackageStatus?.["package_end_date"] || ""}
                      {currentUserPackageStatus?.status === "In-Active" &&
                        userRole !== "Admin" && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`user`}>
                                {common.ADMINMESSAGE}
                              </Tooltip>
                            }
                          >
                            <InfoIcon />
                          </OverlayTrigger>
                        )}
                    </>
                  )}
            </Typography>
          )}
          <Typography
            variant="h5"
            align="center"
            sx={{
              fontWeight: "bold",
              mb: 3,
              fontSize: "1.75rem",
              color: "#333",
            }}
          >
            {plan?.packageName ? (
              plan.packageName
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rounded" width={170} height={35}></Skeleton>
              </div>
            )}
          </Typography>

          {plan ? (
            <Typography
              variant="h4"
              align="center"
              sx={{ color: "primary.main", mb: 4, fontSize: "2rem" }}
            >
              $
              {props.isYearly
                ? parseFloat(plan?.price_annually)
                : parseFloat(plan?.price_monthly)}
              <span style={{ fontSize: "0.5em" }}>
                {" "}
                {props.isYearly ? "/yr" : "/mo"}
              </span>
            </Typography>
          ) : (
            <div
              style={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rounded" width={170} height={40}></Skeleton>
            </div>
          )}
          <Grid container spacing={3} alignItems="center">
            {plan?.packageServiceData ? (
              plan?.packageServiceData
                .filter(props.shouldDisplayService)
                .map((service) => {
                  const serviceValue = props.renderValue(service, plan); // Get the service value
                  return (
                    <Grid item xs={12} sm={6} md={4} key={service.serviceId}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "15px",
                          backgroundColor: "#ffffff",
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          transition: "0.3s",
                          "&:hover": {
                            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                      >
                        {/* Conditionally render icon based on value */}
                        <ListItemIcon
                          sx={{ minWidth: "auto", marginRight: "10px" }}
                        >
                          {service.value === "true" ? (
                            <CheckCircleIcon
                              sx={{ color: "primary.main", fontSize: "1.5rem" }}
                            />
                          ) : service.value === "false" ? (
                            <CancelIcon
                              sx={{ color: "error.main", fontSize: "1.5rem" }}
                            />
                          ) : (
                            // <Tooltip title="No value available">
                            //   </Tooltip>
                            <CheckCircleIcon
                              sx={{
                                color: "primary.main",
                                fontSize: "1.5rem",
                              }}
                            />
                          )}
                        </ListItemIcon>

                        <Box>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", fontSize: "1.2rem" }}
                          >
                            {service.serviceName}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "normal",
                              fontSize: "1rem",
                              color: serviceValue
                                ? "text.secondary"
                                : "error.main",
                            }}
                          >
                            {serviceNameArray?.includes(service.slug) &&
                            userRole !== "Super Admin"
                              ? currentUserPackageStatus?.[service.slug] +
                                " / " +
                                serviceValue
                              : serviceValue || "Not available"}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })
            ) : (
              <ListSkeleton listsToRender={9} />
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MyPackage;
