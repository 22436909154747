import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Typography,
  Card,
  CardContent,
  FormHelperText,
  ListItemText,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  addPackage,
  getAnalyticsServices,
  getPackageServicesById,
  getServices,
  updatePackage,
} from "../../helpers/Help/CommomApi";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Form } from "reactstrap";
import { MenuHeight } from "../NewDashboard/constant";

const AddPackage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [services, setServices] = useState([]);
  const [analyticsServices, setAnalyticsServices] = useState([]);
  const id = params?.id;

  const fetchServices = async () => {
    try {
      const response = await getServices();
      setServices(response?.data?.rows || []);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchAnalyticsServices = async () => {
    try {
      const response = await getAnalyticsServices();
      if (response?.data) {
        setAnalyticsServices(response.data);
      }
    } catch (error) {
      console.error("Error fetching analytics services:", error);
    }
  };

  useEffect(() => {
    fetchAnalyticsServices();
  }, []);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchPackageData = async () => {
    if (id) {
      try {
        const response = await getPackageServicesById(id);
        if (response?.data) {
          const packageData = response?.data.data[0];
          formik.setValues({
            title: packageData.title || "",
            // status: packageData.status || false,
            status: packageData.status === 1,
            price_monthly: packageData.price_monthly || "",
            price_annually: packageData.price_annually || "",
            assets: "",
            users_limits: "",
            uptime_monitoring_interval: "",
            uptime_monitoring_unit: "Minutes",
            uptime_report: "false",
            domain_expiry_notification: "false",
            ssl_expiry_notification: "false",
            security_scan: "false",
            schedule_security_scan: "false",
            analytics_reports: "false",
            security_scan_report_limit: "",
            security_scan_report_limit_unit: "Month",
            schedule_security_report_limit: "",
            schedule_security_report_limit_unit: "Month",
            analytics_services: [],
            performance_report: "false",
            performance_report_limit: "",
            performance_report_limit_unit: "Month",
          });

          packageData.packageServiceData.forEach((service) => {
            const { slugName, value } = service;
            if (value) {
              const parsedValue = value;
              switch (slugName) {
                case "assets":
                  formik.setFieldValue("assets", parsedValue);
                  break;
                case "users_limits":
                  formik.setFieldValue("users_limits", parsedValue);
                  break;
                case "uptime_monitoring_interval":
                  formik.setFieldValue(
                    "uptime_monitoring_interval",
                    parsedValue
                  );
                  break;
                case "uptime_monitoring_unit":
                  formik.setFieldValue("uptime_monitoring_unit", parsedValue);
                  break;
                case "uptime_report":
                  formik.setFieldValue(
                    "uptime_report",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "domain_expiry_notification":
                  formik.setFieldValue(
                    "domain_expiry_notification",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "ssl_expiry_notification":
                  formik.setFieldValue(
                    "ssl_expiry_notification",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "security_scan":
                  formik.setFieldValue(
                    "security_scan",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "schedule_security_scan":
                  formik.setFieldValue(
                    "schedule_security_scan",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "analytics_reports":
                  formik.setFieldValue(
                    "analytics_reports",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "security_scan_report_limit":
                  formik.setFieldValue(
                    "security_scan_report_limit",
                    parsedValue
                  );
                  break;
                case "security_scan_report_limit_unit":
                  formik.setFieldValue(
                    "security_scan_report_limit_unit",
                    parsedValue || "Month"
                  );
                  break;
                case "schedule_security_report_limit":
                  formik.setFieldValue(
                    "schedule_security_report_limit",
                    parsedValue
                  );
                  break;
                case "schedule_security_report_limit_unit":
                  formik.setFieldValue(
                    "schedule_security_report_limit_unit",
                    parsedValue
                  );
                  break;
                case "analytics_services":
                  formik.setFieldValue("analytics_services", parsedValue);
                  break;
                case "performance_report":
                  formik.setFieldValue(
                    "performance_report",
                    parsedValue === "true" ? "true" : "false"
                  );
                  break;
                case "performance_report_limit":
                  formik.setFieldValue("performance_report_limit", parsedValue);
                  break;
                case "performance_report_limit_unit":
                  formik.setFieldValue(
                    "performance_report_limit_unit",
                    parsedValue || "Month"
                  );
                  break;
                default:
                  break;
              }
            }
          });
        }
      } catch (error) {
        console.error("Error fetching package data:", error);
        toast.error("Failed to fetch package data");
      }
    }
  };

  useEffect(() => {
    fetchPackageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const packageServicesData = services
      .map((service) => {
        switch (service.service_name) {
          case "Assets":
            return {
              service_id: service.service_id,
              value: values.assets,
              slug: service.slug,
            };
          case "Users Limits":
            return {
              service_id: service.service_id,
              value: values.users_limits,
              slug: service.slug,
            };
          case "Uptime Report":
            return {
              service_id: service.service_id,
              value: values.uptime_report,
              slug: service.slug,
            };
          case "Uptime Monitoring Interval":
            return {
              service_id: service.service_id,
              value: values.uptime_monitoring_interval,
              slug: service.slug,
            };
          case "Uptime Monitoring Unit":
            return {
              service_id: service.service_id,
              value: values.uptime_monitoring_unit,
              slug: service.slug,
            };
          case "Domain Expiry Notification":
            return {
              service_id: service.service_id,
              value: values.domain_expiry_notification,
              slug: service.slug,
            };
          case "SSL Expiry Notification":
            return {
              service_id: service.service_id,
              value: values.ssl_expiry_notification,
              slug: service.slug,
            };
          case "Security Scan":
            return {
              service_id: service.service_id,
              value: values.security_scan,
              slug: service.slug,
            };
          case "Security Scan Report Limit":
            return {
              service_id: service.service_id,
              value: values.security_scan_report_limit,
              slug: service.slug,
            };
          case "Security Scan Report Limit Unit":
            return {
              service_id: service.service_id,
              value: values.security_scan_report_limit_unit,
              slug: service.slug,
            };
          case "Schedule Security Scan":
            return {
              service_id: service.service_id,
              value: values.schedule_security_scan,
              slug: service.slug,
            };
          case "Schedule Security Report Limit":
            return {
              service_id: service.service_id,
              value: values.schedule_security_report_limit,
              slug: service.slug,
            };
          case "Schedule Security Report Limit Unit":
            return {
              service_id: service.service_id,
              value: values.schedule_security_report_limit_unit,
              slug: service.slug,
            };
          case "Performance Report":
            return {
              service_id: service.service_id,
              value: values.performance_report,
              slug: service.slug,
            };
          case "Performance Report Limit":
            return {
              service_id: service.service_id,
              value: values.performance_report_limit,
              slug: service.slug,
            };
          case "Performance Report Limit Unit":
            return {
              service_id: service.service_id,
              value: values.performance_report_limit_unit,
              slug: service.slug,
            };
          case "Analytics Reports":
            return {
              service_id: service.service_id,
              value: values.analytics_reports,
              slug: service.slug,
            };
          case "Analytics Services":
            return {
              service_id: service.service_id,
              value: values.analytics_services,
              slug: service.slug,
            };
          default:
            return null;
        }
      })
      .filter(Boolean);

    const finalData = {
      title: values.title,
      status: values.status,
      price_monthly: values.price_monthly,
      price_annually: values.price_annually,
      packageServicesData,
    };

    try {
      const response = id
        ? await updatePackage(id, finalData)
        : await addPackage(finalData);

      if (response?.status === "success") {
        toast.success(`Package ${id ? "updated" : "created"} successfully!`);
        navigate("/package");
      }
    } catch (error) {
      console.error(
        `Error ${id ? "updating" : "creating"} package:`,
        error.message
      );
      toast.error(
        `Error ${id ? "updating" : "creating"} package. Please try again.`
      );
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().trim().required("Title is required"),
    price_monthly: Yup.number()
      .typeError(
        "Price (Monthly) must be a valid number. Please enter only numbers."
      )
      .required("Price (Monthly) is required")
      .positive("Price (Monthly) must be positive"),
    price_annually: Yup.number()
      .typeError(
        "Price (Annually) must be a valid number. Please enter only numbers."
      )
      .required("Price (Annually) is required")
      .positive("Price (Annually) must be positive"),
    assets: Yup.number()
      .typeError(
        "Assets (Limit) must be a valid number. Please enter only numbers."
      )
      .required("Assets (Limit) is required")
      .positive("Assets (Limit) must be positive"),
    users_limits: Yup.number()
      .typeError(
        "User (Limit) must be a valid number. Please enter only numbers."
      )
      .required("User (Limit) is required")
      .positive("User (Limit) must be positive"),
    uptime_monitoring_interval: Yup.number()
      .typeError(
        "Monitoring interval must be a valid number. Please enter only numbers."
      )
      .test("is-required", "Monitoring interval is required", function (value) {
        const { uptime_report } = this.parent;
        return uptime_report === "true" ? value != null && value > 0 : true;
      }),
    uptime_monitoring_unit: Yup.string()
      .trim()
      .test(
        "is-required",
        "Monitoring interval Unit is required",
        function (value) {
          const { uptime_report } = this.parent;
          return uptime_report === "true" ? !!value : true;
        }
      ),

    security_scan_report_limit: Yup.number()
      .typeError(
        "Security Scan Report Limit must be a valid number. Please enter only numbers."
      )
      .test(
        "is-required",
        "Security Scan Report Limit is required",
        function (value) {
          const { security_scan } = this.parent;
          return security_scan === "true" ? value != null && value > 0 : true;
        }
      ),
    security_scan_report_limit_unit: Yup.string()
      .trim()
      .test(
        "is-required",
        "Security Scan Report Limit Unit is required",
        function (value) {
          const { security_scan } = this.parent;
          return security_scan === "true" ? !!value : true;
        }
      ),

    schedule_security_report_limit: Yup.number()
      .typeError(
        "Schedule Security Report Limit must be a valid number. Please enter only numbers."
      )
      .test(
        "is-required",
        "Schedule Security Report Limit is required",
        function (value) {
          const { schedule_security_scan } = this.parent;
          return schedule_security_scan === "true"
            ? value != null && value > 0
            : true;
        }
      ),
    schedule_security_report_limit_unit: Yup.string()
      .trim()
      .test(
        "is-required",
        "Schedule Security Report Limit Unit is required",
        function (value) {
          const { schedule_security_scan } = this.parent;
          return schedule_security_scan === "true" ? !!value : true;
        }
      ),

    performance_report_limit: Yup.number()
      .typeError(
        "Performance Report Limit must be a valid number. Please enter only numbers."
      )
      .test(
        "is-required",
        "Performance Report Limit is required",
        function (value) {
          const { performance_report } = this.parent;
          return performance_report === "true"
            ? value != null && value > 0
            : true;
        }
      ),
    performance_report_limit_unit: Yup.string()
      .trim()
      .test(
        "is-required",
        "Performance Report Limit Unit is required",
        function (value) {
          const { performance_report } = this.parent;
          return performance_report === "true" ? !!value : true;
        }
      ),

    analytics_services: Yup.array()
      .test(
        "is-required",
        "At least one Analytics Service is required",
        function (value) {
          const { analytics_reports } = this.parent;
          return analytics_reports === "true"
            ? value && value.length > 0
            : true;
        }
      )
      .of(
        Yup.number().typeError(
          "Analytics Service ID must be a valid number. Please enter only numbers."
        )
      ),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      status: true,
      price_monthly: "",
      price_annually: "",
      assets: "",
      users_limits: "",
      uptime_monitoring_interval: "",
      uptime_monitoring_unit: "",
      uptime_report: "false",
      domain_expiry_notification: "false",
      ssl_expiry_notification: "false",
      security_scan: "false",
      schedule_security_scan: "false",
      analytics_reports: "false",
      security_scan_report_limit: "",
      security_scan_report_limit_unit: "",
      schedule_security_report_limit: "",
      schedule_security_report_limit_unit: "",
      analytics_services: [],
      performance_report: "false",
      performance_report_limit: "",
      performance_report_limit_unit: "",
    },
    validationSchema: validationSchema,

    onSubmit,
  });

  useEffect(() => {
    if (formik.values.performance_report === "true") {
      formik.values.performance_report_limit_unit ||
        formik.setFieldValue("performance_report_limit_unit", "Month");
    } else {
      formik.setFieldValue("performance_report_limit_unit", "");
    }
    if (formik.values.security_scan === "true") {
      formik.values.security_scan_report_limit_unit ||
        formik.setFieldValue("security_scan_report_limit_unit", "Month");
    } else {
      formik.setFieldValue("security_scan_report_limit_unit", "");
    }
    if (formik.values.schedule_security_scan === "true") {
      formik.values.schedule_security_report_limit_unit ||
        formik.setFieldValue("schedule_security_report_limit_unit", "Month");
    } else {
      formik.setFieldValue("schedule_security_report_limit_unit", "");
    }
    if (formik.values.uptime_report === "true") {
      formik.values.uptime_monitoring_unit ||
        formik.setFieldValue("uptime_monitoring_unit", "Minutes");
    } else {
      formik.setFieldValue("uptime_monitoring_unit", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.performance_report,
    formik.values.security_scan,
    formik.values.schedule_security_scan,
    formik.values.uptime_report,
  ]);

  const limitUnitOptions = [
    { value: "Month", label: "Month" },
    { value: "Day", label: "Day" },
  ];

  const uptimeUnitOptions = [
    { value: "Minutes", label: "Minutes" },
    { value: "Hours", label: "Hours" },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Card sx={{ padding: "20px" }}>
        <CardContent>
          {/* <Typography variant="h6" gutterBottom sx={{ marginBottom: "30px" }}>
            {id ? "Edit Package" : "Add Package"}{" "}
          </Typography> */}

          <Form
            onSubmit={formik.handleSubmit}
            className="needs-validation"
            noValidate
            action="index"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  variant="outlined"
                  {...formik.getFieldProps("title")}
                  // value={formik.values.title}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  error={formik.touched.title && !!formik.errors.title}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={formik.values.status}
                      checked={Boolean(formik.values.status)}
                      onChange={formik.handleChange}
                      name="status"
                    />
                  }
                  label="Active"
                />
              </Grid>

              {/* Price Monthly and Annually */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Price (Monthly)"
                  name="price_monthly"
                  // value={values.price_monthly}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // variant="outlined"
                  {...formik.getFieldProps("price_monthly")}
                  error={
                    formik.touched.price_monthly &&
                    !!formik.errors.price_monthly
                  }
                  helperText={
                    formik.touched.price_monthly && formik.errors.price_monthly
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Price (Annually)"
                  name="price_annually"
                  {...formik.getFieldProps("price_annually")}
                  // value={values.price_annually}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  // variant="outlined"
                  error={
                    formik.touched.price_annually &&
                    !!formik.errors.price_annually
                  }
                  helperText={
                    formik.touched.price_annually &&
                    formik.errors.price_annually
                  }
                />
              </Grid>

              {/* Assets Limit and User Limit */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Assets (Limit)"
                  name="assets"
                  variant="outlined"
                  {...formik.getFieldProps("assets")}
                  // value={values.assets}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  error={formik.touched.assets && !!formik.errors.assets}
                  helperText={formik.touched.assets && formik.errors.assets}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="User (Limit)"
                  name="users_limits"
                  variant="outlined"
                  {...formik.getFieldProps("users_limits")}
                  // value={values.users_limits}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                  error={
                    formik.touched.users_limits && !!formik.errors.users_limits
                  }
                  helperText={
                    formik.touched.users_limits && formik.errors.users_limits
                  }
                />
              </Grid>

              {/* Uptime Report and Monitoring Interval */}
              <Grid item xs={12} sm={6}>
                <Typography>Uptime Report</Typography>
                <RadioGroup
                  row
                  name="uptime_report"
                  value={formik.values.uptime_report}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Monitoring interval"
                      name="uptime_monitoring_interval"
                      variant="outlined"
                      {...formik.getFieldProps("uptime_monitoring_interval")}
                      error={
                        formik.touched.uptime_monitoring_interval &&
                        !!formik.errors.uptime_monitoring_interval
                      }
                      helperText={
                        formik.touched.uptime_monitoring_interval &&
                        formik.errors.uptime_monitoring_interval
                      }
                      disabled={formik.values.uptime_report === "false"}
                      value={
                        formik.values.uptime_report === "false"
                          ? (formik.values.uptime_monitoring_interval = "")
                          : formik.values.uptime_monitoring_interval
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.uptime_monitoring_unit &&
                          formik.touched.uptime_monitoring_unit
                      )}
                    >
                      <InputLabel>Interval Unit</InputLabel>
                      <Select
                        label="Interval Unit"
                        name="uptime_monitoring_unit"
                        value={
                          formik.values.uptime_report === "false"
                            ? (formik.values.uptime_monitoring_unit = "")
                            : formik.values.uptime_monitoring_unit
                        }
                        onChange={formik.handleChange}
                        disabled={formik.values.uptime_report === "false"}
                      >
                        {uptimeUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.uptime_monitoring_unit &&
                        formik.touched.uptime_monitoring_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.uptime_monitoring_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Domain Expiry Notification and SSL Expiry Notification */}
              <Grid item xs={12} sm={6}>
                <Typography>Domain Expiry Notification</Typography>
                <RadioGroup
                  row
                  name="domain_expiry_notification"
                  value={formik.values.domain_expiry_notification}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>SSL Expiry Notification</Typography>
                <RadioGroup
                  row
                  name="ssl_expiry_notification"
                  value={formik.values.ssl_expiry_notification}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>

              {/*Security Scan and Report Limit*/}
              <Grid item xs={12} sm={6}>
                <Typography>Security Scan</Typography>
                <RadioGroup
                  row
                  name="security_scan"
                  value={formik.values.security_scan}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Security Report Limit"
                      name="security_scan_report_limit"
                      variant="outlined"
                      {...formik.getFieldProps("security_scan_report_limit")}
                      error={
                        formik.touched.security_scan_report_limit &&
                        !!formik.errors.security_scan_report_limit
                      }
                      helperText={
                        formik.touched.security_scan_report_limit &&
                        formik.errors.security_scan_report_limit
                      }
                      value={
                        formik.values.security_scan === "false"
                          ? (formik.values.security_scan_report_limit = "")
                          : formik.values.security_scan_report_limit
                      }
                      disabled={formik.values.security_scan === "false"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.security_scan_report_limit_unit &&
                          formik.touched.security_scan_report_limit_unit
                      )}
                    >
                      <InputLabel>Limit Unit</InputLabel>
                      <Select
                        label="Limit Unit"
                        name="security_scan_report_limit_unit"
                        value={
                          formik.values.security_scan === "false"
                            ? (formik.values.security_scan_report_limit_unit =
                                "")
                            : formik.values.security_scan_report_limit_unit
                        }
                        onChange={formik.handleChange}
                        disabled={formik.values.security_scan === "false"}
                      >
                        {limitUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.security_scan_report_limit_unit &&
                        formik.touched.security_scan_report_limit_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.security_scan_report_limit_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Schedule Security Scan and Report Limit */}
              <Grid item xs={12} sm={6}>
                <Typography>Schedule Security Scan</Typography>
                <RadioGroup
                  row
                  name="schedule_security_scan"
                  value={formik.values.schedule_security_scan}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Schedule Security Report Limit"
                      name="schedule_security_report_limit"
                      variant="outlined"
                      {...formik.getFieldProps(
                        "schedule_security_report_limit"
                      )}
                      error={
                        formik.touched.schedule_security_report_limit &&
                        !!formik.errors.schedule_security_report_limit
                      }
                      helperText={
                        formik.touched.schedule_security_report_limit &&
                        formik.errors.schedule_security_report_limit
                      }
                      disabled={
                        formik.values.schedule_security_scan === "false"
                      }
                      value={
                        formik.values.schedule_security_scan === "false"
                          ? (formik.values.schedule_security_report_limit = "")
                          : formik.values.schedule_security_report_limit
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.schedule_security_report_limit_unit &&
                          formik.touched.schedule_security_report_limit_unit
                      )}
                    >
                      <InputLabel>Limit Unit</InputLabel>
                      <Select
                        label="Limit Unit"
                        name="schedule_security_report_limit_unit"
                        value={
                          formik.values.schedule_security_scan === "false"
                            ? (formik.values.schedule_security_report_limit_unit =
                                "")
                            : formik.values.schedule_security_report_limit_unit
                        }
                        onChange={formik.handleChange}
                        disabled={
                          formik.values.schedule_security_scan === "false"
                        }
                      >
                        {limitUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.schedule_security_report_limit_unit &&
                        formik.touched.schedule_security_report_limit_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.schedule_security_report_limit_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* performance Scan and Report Limit */}
              <Grid item xs={12} sm={6}>
                <Typography>Performance Scan</Typography>
                <RadioGroup
                  row
                  name="performance_report"
                  value={formik.values.performance_report}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Performance Limit"
                      name="performance_report_limit"
                      variant="outlined"
                      {...formik.getFieldProps("performance_report_limit")}
                      error={
                        formik.touched.performance_report_limit &&
                        !!formik.errors.performance_report_limit
                      }
                      helperText={
                        formik.touched.performance_report_limit &&
                        formik.errors.performance_report_limit
                      }
                      disabled={formik.values.performance_report === "false"}
                      value={
                        formik.values.performance_report === "false"
                          ? (formik.values.performance_report_limit = "")
                          : formik.values.performance_report_limit
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.errors.performance_report_limit_unit &&
                          formik.touched.performance_report_limit_unit
                      )}
                    >
                      <InputLabel>Performance Limit Unit</InputLabel>
                      <Select
                        label="Performance Limit Unit"
                        name="performance_report_limit_unit"
                        value={
                          formik.values.performance_report === "false"
                            ? (formik.values.performance_report_limit_unit = "")
                            : formik.values.performance_report_limit_unit ||
                              "Month"
                        }
                        // value={
                        //   formik.values.performance_report === "false"
                        //     ? ""
                        //     : formik.values.performance_report_limit_unit ||
                        //       "Month"
                        // }
                        onChange={formik.handleChange}
                        disabled={formik.values.performance_report === "false"}
                      >
                        {limitUnitOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.performance_report_limit_unit &&
                        formik.touched.performance_report_limit_unit && (
                          <FormHelperText className="error-text">
                            {formik.errors.performance_report_limit_unit}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* Analytics Report */}
              <Grid item xs={12} sm={6}>
                <Typography>Analytics Report</Typography>
                <RadioGroup
                  row
                  name="analytics_reports"
                  value={formik.values.analytics_reports}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="Disable"
                  />
                </RadioGroup>
              </Grid>
              {/* Selected Services */}
              {/* <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.analytics_services &&
                      formik.touched.analytics_services
                  )}
                >
                  <InputLabel>Analytics Services</InputLabel>
                  <Select
                    label="Analytics Services"
                    name="analytics_services"
                    multiple
                    MenuProps={MenuHeight}
                    value={
                      formik.values.analytics_reports === "false"
                        ? (formik.values.analytics_services = [])
                        : formik.values.analytics_services
                    }
                    onChange={(event) => {
                      const selectedValues = event.target.value;
                      formik.setFieldValue(
                        "analytics_services",
                        selectedValues
                      );
                    }}
                    renderValue={(selected) =>
                      analyticsServices
                        .filter((service) =>
                          selected.includes(service.s_service_id)
                        )
                        .map((service) => service.s_service_name)
                        .join(", ")
                    }
                    disabled={formik.values.analytics_reports === "false"}
                  >
                    {analyticsServices?.map((service) => (
                      <MenuItem
                        key={service?.s_service_id}
                        value={service?.s_service_id}
                      >
                        {service?.s_service_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.analytics_services &&
                    formik.touched.analytics_services && (
                      <FormHelperText className="error-text">
                        {formik.errors.analytics_services}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.analytics_services &&
                      formik.touched.analytics_services
                  )}
                >
                  <InputLabel>Analytics Services</InputLabel>
                  <Select
                    label="Analytics Services"
                    name="analytics_services"
                    multiple
                    MenuProps={MenuHeight}
                    value={
                      formik.values.analytics_reports === "false"
                        ? (formik.values.analytics_services = [])
                        : formik.values.analytics_services
                    }
                    onChange={(event) => {
                      const selectedValues = event.target.value;
                      formik.setFieldValue(
                        "analytics_services",
                        selectedValues
                      );
                    }}
                    renderValue={(selected) =>
                      analyticsServices
                        .filter((service) =>
                          selected.includes(service.s_service_id)
                        )
                        .map((service) => service.s_service_name)
                        .join(", ")
                    }
                    disabled={formik.values.analytics_reports === "false"}
                  >
                    {analyticsServices?.map((service) => (
                      <MenuItem
                        key={service?.s_service_id}
                        value={service?.s_service_id}
                      >
                        <Checkbox
                          checked={formik.values.analytics_services.includes(
                            service.s_service_id
                          )}
                        />
                        <ListItemText primary={service?.s_service_name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.analytics_services &&
                    formik.touched.analytics_services && (
                      <FormHelperText className="error-text">
                        {formik.errors.analytics_services}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={12} container justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  className="btn btn-dark"
                  sx={{ marginRight: 2 }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn btn-primary"
                  onClick={() => navigate("/package")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AddPackage;
