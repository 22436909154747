import { Autocomplete, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDebounce } from "../../EmailLogs/EmailLogs";

const MobileNumberComponent = ({
  setCountryViceNumberLength,
  validation,
  selectedCountryOption = { name: "" },
  setSelectedCountryOption = null,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(selectedCountryOption);

  const [countryDetails, setCountryDetails] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState(
    selectedCountryOption ? selectedCountryOption.name : ""
  );
  const debouncedCountrySearch = useDebounce(searchQuery, 500);


  useEffect(() => {
    if (selectedCountryOption?.name) {
      setSearchQuery(selectedCountryOption.name || "");
      setSelectedCountry({ name: selectedCountryOption?.name || "" });
    }
  }, [selectedCountryOption]);

  useEffect(() => {
    loadCountries(debouncedCountrySearch || "", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCountrySearch]);

  // useEffect(() => {
  //   console.log("Outside useEffect if");
  //   if (selectedCountryOption?.name) {
  //     console.log("Inside useEffect if");
  //     if (!searchQuery) setSearchQuery(selectedCountryOption?.name || "");
  //     if (countries?.length === 1) {
  //       setCountryViceNumberLength(countries?.[0]?.phoneLength);
  //     } else {
  //       const countryInfo = Array.isArray(countries)
  //         ? countries?.find((item) => item.name === selectedCountryOption.name)
  //         : null;
  //       if (countryInfo) setCountryViceNumberLength(countryInfo.phoneLength);
  //     }
  //   }
  // }, [selectedCountryOption, countries, setCountryViceNumberLength]);

  useEffect(() => {
    if (selectedCountryOption?.name && countries.length > 0) {
      const countryInfo = countries.find(
        (item) => item.name === selectedCountryOption.name
      );
      if (countryInfo) {
        setCountryViceNumberLength(countryInfo.phoneLength);
      }
    }
  }, [selectedCountryOption, countries, setCountryViceNumberLength]);

  const loadCountries = async (query = "", reset = false) => {
    if (loading) return;

    setLoading(true);
    try {
      const response = await axios.get("/country/get-countries", {
        params: { page: reset ? 1 : page, limit: 10, query },
      });
      const newData = response?.data || [];
      if (reset) {
        setCountries(newData);
        setPage(2);
      } else {
        setCountries((prev) => [...prev, ...newData]);
        setPage((prev) => prev + 1);
      }
      setHasMore(newData.length === 10);
    } catch (error) {
      console.error("Failed to load countries:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleCountryChange = (event, newValue) => {
  //   const selectedOption =
  //     countries?.find((option) => option.name === newValue?.name) || null;

  //   if (selectedOption) {
  //     setCountryViceNumberLength(selectedOption.phoneLength);
  //     validation.setFieldValue("phone", "");
  //     setSelectedCountry(selectedOption);
  //     setCountryDetails(selectedOption);
  //     setSelectedCountryOption &&
  //       setSelectedCountryOption({ name: selectedOption.name });
  //     validation.setFieldValue("country", selectedOption.name || "");
  //   } else {
  //     setSelectedCountry({ name: "" });
  //     setCountryDetails({});
  //     validation.setFieldValue("country", "");
  //     validation.setFieldValue("phone", "");
  //   }
  // };

  const handleCountryChange = (event, newValue) => {
    if (Array.isArray(countries) && newValue?.name) {
      const selectedOption = countries.find(
        (option) => option.name === newValue.name
      );

      if (selectedOption) {
        setCountryViceNumberLength(selectedOption.phoneLength);
        validation.setFieldValue("phone", "");
        setSelectedCountry(selectedOption);
        setCountryDetails(selectedOption);
        setSelectedCountryOption &&
          setSelectedCountryOption({ name: selectedOption.name });
        validation.setFieldValue("country", selectedOption.name || "");
      } else {
        // If no match is found, reset to an empty country
        setSelectedCountry({ name: "" });
        setCountryDetails({});
        validation.setFieldValue("country", "");
        validation.setFieldValue("phone", "");
      }
    } else {
      // Handle the case where `newValue` is null (e.g., when clearing the input)
      setSelectedCountry({ name: "" });
      setCountryDetails({});
      validation.setFieldValue("country", "");
      validation.setFieldValue("phone", "");
    }
  };

  const handleSearch = (event, newInputValue) => {
    setSearchQuery(newInputValue?.toLowerCase() || "");
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <Autocomplete
        // value={selectedCountry.name ? selectedCountry : { name: "" }}
        value={selectedCountry && selectedCountry.name ? selectedCountry : null}
        // value={selectedCountry?.name ? selectedCountry : null}
        // options={countries}
        options={Array.isArray(countries) ? countries : []}
        getOptionLabel={(option) => option?.name || ""}
        loading={loading}
        onInputChange={handleSearch}
        onChange={handleCountryChange}
        // isOptionEqualToValue={(option, value) =>
        //   (option?.name || "") === (value?.name || "")
        // }
        // isOptionEqualToValue={(option, value) => {
        //   console.log("Option -->", option, "Value -->", value);
        //   if (!value || !value.name) return false;
        //   return option.name === value.name;
        // }}
        // isOptionEqualToValue={(option, value) => option.name === value?.name}
        isOptionEqualToValue={(option, value) => {
          return option && value && option.name === value.name;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select country"
            fullWidth
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
              style: { height: "50px", width: "200px" },
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={option.image || ""}
                alt={option.name || "Country"}
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
                loading="lazy"
              />
              <span>{option.name || "Unknown"}</span>
              {option.phone && (
                <b style={{ marginLeft: 4 }}>({JSON.parse(option.phone)[0]})</b>
              )}
            </div>
          </li>
        )}
        ListboxProps={{
          onScroll: (event) => {
            const target = event.target;
            const bottom =
              Math.abs(
                target.scrollHeight - target.clientHeight - target.scrollTop
              ) <= 1;

            if (bottom && !loading && hasMore) {
              loadCountries(searchQuery);
            }
          },
          style: { maxHeight: 300 },
        }}
      />

      <TextField
        fullWidth
        name="phone"
        variant="outlined"
        sx={{ height: "50px" }}
        onChange={validation.handleChange}
        value={validation.values.phone || ""}
        onBlur={validation.handleBlur}
        error={Boolean(validation.errors.phone && validation.touched.phone)}
        helperText={
          validation.errors.phone && validation.touched.phone
            ? validation.errors.phone
            : ""
        }
        placeholder={
          countryDetails.phoneLength
            ? `Enter phone number (${countryDetails.phoneLength})`
            : "Enter phone number"
        }
        InputProps={{
          style: { height: "50px" },
        }}
      />
    </div>
  );
};

export default MobileNumberComponent;
