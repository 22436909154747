import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback, Input, Spinner } from "reactstrap";
import axios from "axios";

//import logo
import logoSm from "../assets/images/logo-sm.png";

//Import Components
import VerticalLayout from "./VerticalLayouts";
import TwoColumnLayout from "./TwoColumnLayout";
import { Container } from "reactstrap";
import HorizontalLayout from "./HorizontalLayout";
import { ToastMessage } from "../Components/Common/ToastMessage";
import { useSelector } from "react-redux";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import common from "../Components/Common/common";
import { API_ROUTES } from "../Api/ApiRoutes";
import { MenuHeight } from "../pages/NewDashboard/constant";

const Sidebar = ({ layoutType }) => {
  const navigate = useNavigate();

  const params = useParams();
  const handleShow = () => setShow(true);

  const [link, setLink] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTicketLoading, setIsTicketLoading] = useState(null);
  const [websiteGroupOptions, setWebsiteGroupOptions] = useState([]);

  const [logoImage, setLogoImage] = useState("");

  const settingsData = useSelector(
    (state) =>
      state?.settingsData?.generalSettingData?.find(
        (a) => a?.field_name === "FRONT_LOGO_ID"
      )?.field_value
  );

  useEffect(() => {
    setLogoImage(`${process.env.REACT_APP_BASE_URL}${settingsData}`);
  }, [settingsData]);

  const projectOptions = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  const [showTicketStatusModal, setShowTicketStatusModal] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");

  const handleShowTicketStatusModal = () => setShowTicketStatusModal(true);
  const handleCloseTicketStatusModal = () => {
    setShowTicketStatusModal(false);
    setTicketNumber("");
  };

  const handleCheckTicketStatus = async () => {
    setIsTicketLoading(true);
    const ticketID = parseInt(ticketNumber);

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Newticketing-system/ticket/${ticketID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const decryptedData = common.decrypt(response.data);
      response.data = decryptedData;

      if (response.data && response.data.ticket_id === ticketID) {
        const matchingTicket = response.data;

        if (matchingTicket) {
          navigate(`/ticketing-system/${ticketID}`);
          handleCloseTicketStatusModal();
          setIsTicketLoading(false);
        } else {
          ToastMessage.Error(
            "Ticket ID not found. Please enter a valid ticket ID."
          );
          setIsTicketLoading(false);
        }
      } else {
        ToastMessage.Error(
          "An error occurred while fetching ticket details. Please try again."
        );
        setIsTicketLoading(false);
      }
    } catch (error) {
      setIsTicketLoading(false);
      console.error("Error fetching ticket details:", error);
      ToastMessage.Error(
        "An error occurred while fetching ticket details. Please try again."
      );
    }
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      description: "",
      priority: "",
      ticket: null,
      link: window.location.href ? window.location.href : null,
      department_id: "",
    },

    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(5, "Title must be a 5 charcter long")
        .max(50, "Title must be a 50 charcter long")
        .required("Please enter the title."),
      description: Yup.string()
        .min(10, "Discription must be a 10 charcter long")
        .required("Please enter the description."),
      department_id: Yup.string().required("Please select website category."),
      priority: Yup.string().required("Please select the priority."),
      ticket: Yup.mixed()
        .nullable()
        .test(
          "fileSize",
          "File size is too large, file should be max 1MB.",
          (value) => {
            // return value && value.size <= 10485760; // 10 MB in bytes
            return !value || (value && value.size <= 1048576);
          }
        )
        .test(
          "fileType",
          "Invalid file type.  Only JPEG, JPG, PNG files are allowed. Please upload a file with one of these formats.",
          (value) => {
            return (
              !value ||
              (value &&
                ["image/jpeg", "image/jpg", "image/png"].includes(value.type))
            );
          }
        ),
    }),
    onSubmit: (values) => {
      helpCenterAddTicket(values);
    },
  });

  const helpCenterAddTicket = async (values) => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      if (values.ticket && values.ticket.constructor === File) {
        formData.append("ticket-image", values.ticket);
      }

      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("priority", values.priority);
      formData.append("link", link);
      formData.append("asset_id", params.id);
      formData.append("department_id", values.department_id);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}Newticketing-system/Add-ticket`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: formData,
      };

      const ticketAddedResult = await axios.request(config);

      if (ticketAddedResult.status === "success" && ticketAddedResult.data) {
        setShow(false);
        formik.resetForm();
        ToastMessage.Success("Ticket has been successfully added");
        navigate("/ticketing-system");
        setIsLoading(false);
      }
    } catch (error) {
      ToastMessage.Error(error);
      formik.resetForm();
      setIsLoading(false);
    }
  };

  const fetchWebsiteCategories = async () => {
    try {
      let { status, data } = await axios.get(API_ROUTES.GET_WEBSITE_CATEGORIES);
      const responseData = common.decrypt(data);
      if (status === "success" && responseData) {
        setWebsiteGroupOptions(
          responseData.map((item) => {
            return {
              label: item.category,
              value: item.category,
              id: item.id,
            };
          })
        );
      }
    } catch (err) {
      console.error(err.message);
      setWebsiteGroupOptions([]);
    }
  };

  useEffect(() => {
    fetchWebsiteCategories();
  }, []);

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
    setLink(window.location.href);
  }, []);

  return (
    <React.Fragment>
      <Box className="app-menu navbar-menu">
        <Box className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSm} alt="" width="70%" />
            </span>
            <span className="logo-lg">
              <img src={logoImage} height="22px" alt="logo" width="207px" />
            </span>
          </Link>
        </Box>
        {layoutType === "horizontal" ? (
          <SimpleBar
            id="scrollbar"
            forceVisible="y"
            className="px-0"
            style={{ height: "calc(155vh - 468px)", marginTop: "20px" }}
          >
            <Container fluid>
              <Box id="two-column-menu"></Box>
              <ul className="navbar-nav" id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </Container>
          </SimpleBar>
        ) : layoutType === "twocolumn" ? (
          <React.Fragment>
            <TwoColumnLayout layoutType={layoutType} />
            <Box className="sidebar-background"></Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SimpleBar
              id="scrollbar"
              data-simplebar="init"
              forceVisible="y"
              className="px-0"
              style={{ height: "calc(100vh - 116px - 70px )" }}
            >
              <Container fluid>
                <Box id="two-column-menu"></Box>
                <ul className="navbar-nav" id="navbar-nav">
                  <VerticalLayout layoutType={layoutType} />
                </ul>
              </Container>
            </SimpleBar>
            <Box className="sidebar-background"></Box>
          </React.Fragment>
        )}

        <Box className="navbar-footer">
          <ul className="quick-menu d-flex justify-content-between text-center">
            <li>
              <Box className="dropdown help-dropdown dropend">
                <Link
                  className="help-btn"
                  to="/#"
                  data-bs-toggle="dropdown"
                  title="Help Center"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <HelpOutlinedIcon /> &nbsp;
                  <span className="nav-title">Help Center</span>
                </Link>
                <Box className="dropdown-menu">
                  <h6 className="dropdown-header">Help &amp; Support</h6>
                  <Link
                    style={{ color: "black" }}
                    className="dropdown-item title_fixed title_icon_ticket"
                    title="Check Ticket Status"
                    onClick={handleShowTicketStatusModal}
                  >
                    <span className="align-middle">Check Ticket Status</span>
                  </Link>
                  <Link
                    style={{ color: "black" }}
                    className="dropdown-item title_fixed title_icon_ticket"
                    title="Submit a Ticket"
                    onClick={handleShow}
                  >
                    <span className="align-middle">Submit a Ticket</span>
                  </Link>
                  <hr />
                  <h6 className="dropdown-header">Have a question?</h6>
                  <span
                    style={{
                      color: "black",
                      cursor: "pointer", // Mimics the clickability of a link
                      textDecoration: "none", // Removes underline like a link
                      display: "inline-block", // Helps with alignment
                    }}
                    className="dropdown-item"
                    title="Call Us on: +1 (345)-936-2222"
                  >
                    <strong>Call: </strong>
                    <a
                      href="tel:+13459362222"
                      style={{
                        color: "black",
                        marginRight: "8px",
                        textDecoration: "none",
                      }}
                    >
                      +1 (345)-936-2222
                    </a>
                  </span>
                </Box>
              </Box>
            </li>
          </ul>

          <Box className="fixed_from" id="Test">
            <Modal show={show} onHide={handleClose} size="md">
              <Modal.Header closeButton>
                <Modal.Title>Submit a Ticket</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Box className="mb-3">
                    <TextField
                      fullWidth
                      label="Title"
                      name="title"
                      placeholder="Enter the ticket title"
                      value={formik.values.title}
                      // required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.errors.title && formik.touched.title
                          ? true
                          : false
                      }
                    />
                    {formik.errors.title && formik.touched.title ? (
                      <span className="error-text">{formik.errors.title}</span>
                    ) : null}
                  </Box>

                  <Box className="mb-3">
                    <TextField
                      fullWidth
                      label="Description"
                      variant="outlined"
                      id="outlined-size-small"
                      name="description"
                      // required
                      placeholder="Enter Short Description"
                      value={formik.values.description}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      multiline
                      rows={2}
                    />
                    {formik.errors.description &&
                      formik.touched.description && (
                        <span className="error-text">
                          {formik.errors.description}
                        </span>
                      )}
                  </Box>

                  <Box className=" mb-3">
                    <FormControl fullWidth>
                      <InputLabel>Website category *</InputLabel>
                      <Select
                        label="Website category *"
                        fullWidth
                        name="department_id"
                        value={
                          websiteGroupOptions.find(
                            (option) =>
                              option.id === formik.values.department_id
                          )?.id || ""
                        }
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          // const selectedOption = websiteGroupOptions.find(
                          //   (option) => option.id === selectedValue
                          // );
                          formik.setFieldValue("department_id", selectedValue);
                          // setWebsiteGroupOpt(selectedOption);
                        }}
                        MenuProps={MenuHeight}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <>Website category</>;
                          }
                          return websiteGroupOptions.find(
                            (option) => option.id === selected
                          )?.label;
                        }}
                      >
                        {websiteGroupOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.errors.department_id &&
                      formik.touched.department_id && (
                        <span className="error-text">
                          {formik.errors.department_id}
                        </span>
                      )}
                  </Box>

                  <Box className="mb-3">
                    <FormControl fullWidth>
                      <InputLabel id="priority-label">
                        Select the priority *
                      </InputLabel>
                      <Select
                        labelId="priority-label"
                        id="priority"
                        name="priority"
                        value={formik.values.priority}
                        onChange={(event) => {
                          formik.setFieldValue("priority", event.target.value);
                        }}
                        onBlur={formik.handleBlur}
                        label="Select the priority *"
                      >
                        {projectOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.errors.priority && formik.touched.priority ? (
                      <span className="error-text">
                        {formik.errors.priority}
                      </span>
                    ) : null}
                  </Box>

                  <Box className="mb-3">
                    <TextField
                      fullWidth
                      label="Link"
                      bsSize="small"
                      id="outlined-size-small"
                      name="link"
                      placeholder="Enter Your Link."
                      value={formik.values.link}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.errors.link && formik.touched.link ? true : false
                      }
                    />
                    {formik.errors.link && formik.touched.link ? (
                      <FormFeedback type="invalid" style={{ color: "red" }}>
                        {formik.errors.link}
                      </FormFeedback>
                    ) : null}
                  </Box>
                  <Box className="mb-3">
                    <label
                      htmlFor="image"
                      className="d-flex align-items-center"
                    >
                      Upload Image
                      <Tooltip
                        title={common.LOGOTOOLTIP}
                        placement="top-start"
                        arrow
                      >
                        <i
                          className="ri-information-line fs-18 cursor-pointer ms-2"
                          style={{
                            color: "#ced4da",
                          }}
                        ></i>
                      </Tooltip>
                    </label>
                    <Input
                      className="form-control"
                      type="file"
                      id="ticket"
                      name="ticket"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "ticket",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={formik.handleBlur}
                      invalid={formik.errors.ticket && formik.touched.ticket}
                    />
                    {formik.errors.ticket && formik.touched.ticket && (
                      <FormFeedback type="invalid" style={{ color: "red" }}>
                        {formik.errors.ticket}
                      </FormFeedback>
                    )}
                  </Box>
                  <Box className="d-flex align-items-center justify-content-end mt-4">
                    <Box>
                      <Button className="btn btn-dark" type="submit">
                        {isLoading ? (
                          <Spinner size="sm" variant="light" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Box>
                    <Box style={{ paddingLeft: "10px" }}>
                      <Button className="btn btn-primary" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              </Modal.Body>
            </Modal>
          </Box>

          <Box className="ticket">
            <Modal
              show={showTicketStatusModal}
              onHide={handleCloseTicketStatusModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Check Ticket Status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formTicketNumber">
                    <Form.Label>Ticket Number:</Form.Label>
                    <Form.Control
                      type="text"
                      value={ticketNumber}
                      onChange={(e) => setTicketNumber(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn btn-dark"
                  onClick={handleCheckTicketStatus}
                >
                  {isTicketLoading ? (
                    <Spinner size="sm" variant="light" />
                  ) : (
                    "Submit"
                  )}
                </Button>
                <Button
                  className="btn btn-primary"
                  onClick={handleCloseTicketStatusModal}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </Box>

          <Box className="design-by">
            <span>
              {/* © 2022 Netclues. |{" "} */}© {new Date().getFullYear()}{" "}
              Netclues. |{" "}
              <Link
                className="addTermsandCondition"
                title="Terms &amp; Conditions"
                target="#"
                to="https://www.netclues.com/terms-conditions"
              >
                Terms &amp; Conditions
              </Link>
            </span>
          </Box>
        </Box>
      </Box>
      <Box className="vertical-overlay"></Box>
    </React.Fragment>
  );
};

export default Sidebar;
