import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getPackagesServices,
  getUserPackageLimit,
} from "../../helpers/Help/CommomApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import MyPackage from "./MyPackage";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../Components/Common/FormatDateTIme";

const AllPackage = () => {
  const userId = localStorage.getItem("user_id");
  const [packageServiceData, setPackageServiceData] = useState([]);
  const [isYearly, setIsYearly] = useState(true);
  const [myPackage, setMyPackage] = useState([]);
  const [currentUserPackageStatus, setCurrentUserPackageStatus] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const generalSettingData = useSelector(
    (state) => state.settingsData.settingsData
  );
  const otherSettingsData = generalSettingData?.filter(
    (item) => item.settings_category === "other"
  );

  const dateFormat = otherSettingsData?.find(
    (date) => date.field_name === "DEFAULT_DATE_FORMAT"
  )?.field_value;

  const timeZone = otherSettingsData?.find(
    (time) => time.field_name === "DEFAULT_TIME_FORMAT"
  )?.field_value;

  const timeFormat = otherSettingsData?.find(
    (zone) => zone.field_name === "DEFAULT_TIME_ZONE"
  )?.field_value;

  const fetchPackageServiceData = async () => {
    try {
      const response = await getPackagesServices();
      setPackageServiceData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchPackageServiceDataByUserId = async (userId) => {
    try {
      const response = await getPackagesServices(userId); // Fetch by userId
      setMyPackage(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchPackageServiceData();
  }, []);

  useEffect(() => {
    fetchPackageServiceDataByUserId(userId);
  }, [userId]);

  const renderValue = (service, plan) => {
    if (service.slug === "uptime_report") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_interval"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "uptime_monitoring_unit"
        )?.value;
        return `${interval} ${unit.toLowerCase()}. monitoring interval`;
      }
      return "Not Available";
    } else if (service.slug === "performance_report") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "performance_report_limit"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "performance_report_limit_unit"
        )?.value;
        return `${interval} / ${unit.toLowerCase()}`;
      }
      return "Not Available";
    } else if (service.slug === "security_scan") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "security_scan_report_limit_unit"
        )?.value;
        return `${interval} / ${unit.toLowerCase()}`;
      }
      return "Not Available";
    } else if (service.slug === "schedule_security_scan") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "schedule_security_report_limit"
        )?.value;
        const unit = plan.packageServiceData.find(
          (s) => s.slug === "schedule_security_report_limit_unit"
        )?.value;
        return `${interval} / ${unit.toLowerCase()}`;
      }
      return "Not Available";
    } else if (service.slug === "analytics_reports") {
      if (service.value === "true") {
        const interval = plan.packageServiceData.find(
          (s) => s.slug === "analytics_services"
        )?.value;

        return `${interval}`;
      }
      return "Not Available";
    }

    if (service.value === null) return "Not Available";
    if (service.value === "true") return "Yes";
    if (service.value === "false") return "No";
    if (Array.isArray(service.value)) return service.value.join(", ");
    return service.value;
  };

  const shouldDisplayService = (service) => {
    return ![
      "uptime_monitoring_interval",
      "uptime_monitoring_unit",
      "performance_report_limit",
      "performance_report_limit_unit",
      "security_scan_report_limit",
      "security_scan_report_limit_unit",
      "schedule_security_report_limit",
      "schedule_security_report_limit_unit",
      "analytics_services",
      "custom_package",
    ].includes(service.slug);
  };

  const isCurrentPackage = (plan) => {
    return myPackage && plan?.packageId === myPackage[0]?.packageId;
  };

  const handleSelectPlan = () => {
    navigate("/contact-us");
  };

  const fetchUserPackageLimit = async (user_id) => {
    try {
      let response = await getUserPackageLimit(user_id);
      if (
        response.data?.package_end_date &&
        response.data?.package_end_date !== null
      ) {
        const packagEndDate =
          formatDateTime(
            response.data?.package_end_date,
            dateFormat,
            timeZone,
            timeFormat
          ).timeZone.formattedDateFinal +
          " - " +
          formatDateTime(
            response.data?.package_end_date,
            dateFormat,
            timeZone,
            timeFormat
          ).timeZone.formattedTimed;

        response.data["package_end_date"] = packagEndDate || null;
      }
      setCurrentUserPackageStatus(response?.data || null);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchUserPackageLimit(userId);
  }, []);

  return (
    <Box sx={{ padding: "15px" }}>
      <MyPackage
        isYearly={isYearly}
        setIsYearly={setIsYearly}
        myPackage={myPackage}
        renderValue={renderValue}
        shouldDisplayService={shouldDisplayService}
        currentUserPackageStatus={currentUserPackageStatus}
      />
      <Typography variant="h4" align="center" gutterBottom>
        Package Plans
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <Typography variant="body1" sx={{ mr: 2 }}>
          Monthly
        </Typography>
        <Switch
          checked={isYearly}
          onChange={() => setIsYearly(!isYearly)}
          color="primary"
        />
        <Typography variant="body1" sx={{ ml: 2 }}>
          Yearly
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {isLoading ? (
          <>
            {/* <ListSkeleton listsToRender={3} /> */}
            {Array(3)
              .fill(1)
              .map((item, idx) => {
                return (
                  <Grid item key={idx} xs={12} sm={6} md={4}>
                    <Card
                      variant="outlined"
                      sx={{
                        borderRadius: "10px",
                        height: "100%",
                        transition: "0.3s",
                        "&:hover": {
                          boxShadow: 3,
                          transform: "scale(1.03)",
                        },
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography
                          variant="h5"
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            mb: 2,
                            fontSize: "1.5rem",
                            // marginInline: "20%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Skeleton variant="text" width={150} height={50} />
                          </div>
                        </Typography>
                        <Typography
                          variant="h4"
                          align="center"
                          sx={{
                            color: "primary.main",
                            mb: 2,
                            fontSize: "1.75rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Skeleton variant="text" width={70} height={50} />
                          </div>
                        </Typography>
                        <List>
                          {Array(3)
                            .fill(1)
                            .map(
                              (item, idx) => {
                                return (
                                  <React.Fragment key={idx}>
                                    <Skeleton
                                      variant="text"
                                      width={150}
                                      height={30}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width={250}
                                      height={30}
                                    />
                                    <Skeleton
                                      variant="text"
                                      width={150}
                                      height={30}
                                    />
                                    {/* <Skeleton
                                      variant="text"
                                      width={250}
                                      height={30}
                                    /> */}
                                  </React.Fragment>
                                );
                              }

                              // <ListItem key={idx} disablePadding>
                              //   <ListItemText
                              //     primary={
                              //       <Typography
                              //         variant="body2"
                              //         sx={{
                              //           fontWeight: "bold",
                              //           fontSize: "1.1rem",
                              //         }}
                              //       >
                              //         {/* {service.serviceName}:{" "} */}
                              //         service-name
                              //         <span
                              //           style={{
                              //             fontWeight: "normal",
                              //             fontSize: "1rem",
                              //           }}
                              //         >
                              //           {/* {renderValue(service, plan)} */}
                              //           renderValue
                              //         </span>
                              //       </Typography>
                              //     }
                              //     primaryTypographyProps={{ align: "center" }}
                              //   />
                              // </ListItem>
                            )}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
          </>
        ) : (
          packageServiceData.map((plan) => (
            <Grid item key={plan.packageId} xs={12} sm={6} md={4}>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  height: "100%",
                  transition: "0.3s",
                  "&:hover": {
                    boxShadow: 3,
                    transform: "scale(1.03)",
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h5"
                    align="center"
                    sx={{ fontWeight: "bold", mb: 2, fontSize: "1.5rem" }}
                  >
                    {plan.packageName}
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{ color: "primary.main", mb: 2, fontSize: "1.75rem" }}
                  >
                    $
                    {isYearly
                      ? parseFloat(plan?.price_annually)
                      : parseFloat(plan?.price_monthly)}
                    <span style={{ fontSize: "0.5em" }}>
                      {" "}
                      {isYearly ? "/yr" : "/mo"}
                    </span>
                  </Typography>
                  <List>
                    {plan.packageServiceData
                      .filter(shouldDisplayService)
                      .map((service) => (
                        <ListItem key={service.serviceId} disablePadding>
                          {/* Conditionally render icon based on value */}
                          <ListItemIcon
                            sx={{ minWidth: "auto", marginRight: "10px" }}
                          >
                            {service.value === "true" ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "primary.main",
                                  fontSize: "1.5rem",
                                }}
                              />
                            ) : service.value === "false" ? (
                              <CancelIcon
                                sx={{ color: "error.main", fontSize: "1.5rem" }}
                              />
                            ) : (
                              <CheckCircleIcon
                                sx={{
                                  color: "primary.main",
                                  fontSize: "1.5rem",
                                }}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold", fontSize: "1.1rem" }}
                              >
                                {service.serviceName}:{" "}
                                <span
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {renderValue(service, plan)}
                                </span>
                              </Typography>
                            }
                            primaryTypographyProps={{ align: "center" }}
                          />
                        </ListItem>
                      ))}
                  </List>
                </CardContent>

                <Box sx={{ p: 2, mt: "auto" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSelectPlan(plan)}
                    disabled={isCurrentPackage(plan)}
                    sx={{
                      backgroundColor: isCurrentPackage(plan)
                        ? "grey.400"
                        : "black",
                      color: isCurrentPackage(plan) ? "grey.700" : "white",
                      borderRadius: "20px",
                      px: 4,
                      "&:hover": {
                        backgroundColor: "darkgray", // Optional: change color on hover
                      },
                    }}
                  >
                    {isCurrentPackage(plan) ? "Current Plan" : "Select Plan"}
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))
        )}

        {/* Custom Package Card */}
        <Grid item xs={12} sm={6} md={4} marginTop={"30px"}>
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              transition: "0.3s",
              "&:hover": {
                boxShadow: 3,
                transform: "scale(1.03)",
              },
            }}
          >
            <CardContent
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                align="center"
                sx={{ fontWeight: "bold", mb: 2, fontSize: "1.5rem" }}
              >
                Custom Package
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{
                  mb: 2,
                  fontSize: "1.2rem",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "150px",
                }}
              >
                Request a Call for Custom Package
              </Typography>
            </CardContent>

            <Box sx={{ p: 2, mt: "auto" }}>
              <Link to="/contact-us" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  // color="primary"
                  fullWidth
                  sx={{
                    backgroundColor: "black", // Set background color to black
                    color: "white", // Change text color to white for contrast
                    borderRadius: "20px",
                    px: 4,
                    "&:hover": {
                      backgroundColor: "darkgray", // Optional: change color on hover
                    },
                  }}
                  // sx={{ borderRadius: "20px" }}
                >
                  Contact Us
                </Button>
              </Link>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllPackage;
