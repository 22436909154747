import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastMessage } from "../../Components/Common/ToastMessage";
import { useNavigate } from "react-router-dom";

const ContactUsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const defaultEmail = useSelector(
    (state) =>
      state?.settingsData?.settingsData?.find(
        (item) => item.field_name === "DEFAULT_CONTACTUS_EMAIL"
      )?.field_value || ""
  );

  const {
    user_name = "",
    email = "",
    contact_no = "",
  } = useSelector((state) => state?.userDetails?.userData || {});

  const sendTheContactEmail = async ({
    description,
    email,
    name,
    mobilenumber,
  }) => {
    try {
      setIsLoading(true);
      const response = await axios.post("/package/contact-us", {
        description,
        email,
        name,
        mobilenumber,
        defaultEmail,
      });

      if (response.status === "success") {
        ToastMessage.Success(response?.data?.message);
        navigate("/upgrade-package");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required."),
    mobilenumber: Yup.string()
      // .matches(/^\d{10}$/, "Please enter valide mobile number")
      .matches(
        /^(\d{10}|\(\d{3}\) \d{3} \d{4})$/,
        "Please enter a valid mobile number (either 10 digits or in the format (324) 354 8648)"
      )
      .nullable()
      .notRequired(),
    description: Yup.string()
      .trim()
      .min(10, "Description must be at least 10 characters long.")
      .required("Description is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user_name || "",
      email: email || "",
      mobilenumber: contact_no || "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => sendTheContactEmail(values),
  });

  //1 layout
  // return (
  //   <Box sx={{ padding: "20px" }}>
  //     <Typography variant="h4" align="center" gutterBottom>
  //       Contact Us
  //     </Typography>
  //     <Grid container justifyContent="center">
  //       <Grid item xs={12} sm={8} md={6}>
  //         <Card variant="outlined" sx={{ p: 3 }}>
  //           <CardContent>
  //             <form onSubmit={formik.handleSubmit}>
  //               <Grid container spacing={2}>
  //                 <Grid item xs={12}>
  //                   <TextField
  //                     label="Name*"
  //                     name="name"
  //                     value={formik.values.name}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     fullWidth
  //                     error={formik.touched.name && Boolean(formik.errors.name)}
  //                     helperText={formik.touched.name && formik.errors.name}
  //                     disabled={true}
  //                   />
  //                 </Grid>
  //                 <Grid item xs={12}>
  //                   <TextField
  //                     label="Email*"
  //                     name="email"
  //                     value={formik.values.email}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     fullWidth
  //                     type="email"
  //                     error={
  //                       formik.touched.email && Boolean(formik.errors.email)
  //                     }
  //                     helperText={formik.touched.email && formik.errors.email}
  //                     disabled={true}
  //                   />
  //                 </Grid>

  //                 <Grid item xs={12}>
  //                   <TextField
  //                     label="MobileNumber"
  //                     name="mobilenumber"
  //                     value={formik.values.mobilenumber}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     fullWidth
  //                     type="text"
  //                     error={
  //                       formik.touched.mobilenumber &&
  //                       Boolean(formik.errors.mobilenumber)
  //                     }
  //                     helperText={
  //                       formik.touched.mobilenumber &&
  //                       formik.errors.mobilenumber
  //                     }
  //                   />
  //                 </Grid>
  //                 <Grid item xs={12}>
  //                   <TextField
  //                     label="Description*"
  //                     name="description"
  //                     value={formik.values.description}
  //                     onChange={formik.handleChange}
  //                     onBlur={formik.handleBlur}
  //                     fullWidth
  //                     multiline
  //                     rows={4}
  //                     error={
  //                       formik.touched.description &&
  //                       Boolean(formik.errors.description)
  //                     }
  //                     helperText={
  //                       formik.touched.description && formik.errors.description
  //                     }
  //                   />
  //                 </Grid>
  //               </Grid>

  //               <Box sx={{ textAlign: "center", mt: 3 }}>
  //                 <Button
  //                   variant="contained"
  //                   color="primary"
  //                   type="submit"
  //                   sx={{ borderRadius: "20px", px: 4 }}
  //                   disabled={isLoading} // Disable button while loading
  //                   startIcon={
  //                     isLoading ? (
  //                       <CircularProgress size={20} color="inherit" />
  //                     ) : null
  //                   }
  //                 >
  //                   {isLoading ? "Sending email please wait..." : "Send mail"}
  //                 </Button>
  //               </Box>
  //             </form>
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //     </Grid>
  //   </Box>
  // );

  //Modified
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Card
            variant="elevation"
            elevation={3}
            sx={{
              borderRadius: "16px",
              padding: { xs: 3, sm: 5 },
              backgroundColor: "#fff",
            }}
          >
            <CardContent>
              <Typography
                variant="h3"
                align="center"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "24px", sm: "30px" },
                  color: "#333",
                  marginBottom: "20px",
                }}
              >
                Contact Us
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{
                  color: "#666",
                  fontSize: { xs: "14px", sm: "16px" },
                  marginBottom: "30px",
                }}
              >
                We’d love to hear from you! Please fill out the form below.
              </Typography>

              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Name*"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email*"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      type="email"
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Mobile Number"
                      name="mobilenumber"
                      value={formik.values.mobilenumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      type="text"
                      disabled={!!contact_no}
                      error={
                        formik.touched.mobilenumber &&
                        Boolean(formik.errors.mobilenumber)
                      }
                      helperText={
                        formik.touched.mobilenumber &&
                        formik.errors.mobilenumber
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description*"
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      multiline
                      rows={4}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                </Grid>

                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    // sx={{ borderRadius: "20px", px: 4 }}
                    sx={{
                      backgroundColor: "black", // Set background color to black
                      color: "white", // Change text color to white for contrast
                      borderRadius: "20px",
                      px: 4,
                      "&:hover": {
                        backgroundColor: "darkgray", // Optional: change color on hover
                      },
                    }}
                    disabled={isLoading} // Disable button while loading
                    startIcon={
                      isLoading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isLoading ? "Sending email please wait..." : "Send mail"}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUsForm;
