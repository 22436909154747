import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { setActiveTab } from "../store/settingTab/action";
import {
  ONLINE_ASSETS,
  USERS,
  DEVELOPER_PROFILE,
  ACCESS_RIGHTS,
  WEBSITE_GUIDELINE,
  BRANDING_GUIDELINE,
  WEBSITE_STATISTICS,
  ANNOUNCEMENTS,
  TICKETING_SYSTEM,
  UPTIME_REPORT,
  HIT_REPORT,
  PERFORMANCE,
  WEB_SECURITY,
} from "../Components/Common/ModuleName";
import {
  FlieChartLineIcon,
  GuidAltIcon,
  HomeIcon,
  LabelIcon,
  SiteAltIcon,
} from "../Components/Common/SvgIcons";
import LanguageIcon from "@mui/icons-material/Language";
import GroupIcon from "@mui/icons-material/Group";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
const Navdata = (path) => {
  const history = useNavigate();
  // const dispatch = useDispatch();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [iswebsitemanagement, setIsWebsitemanagement] = useState(false);
  const [isUsermanagement, setIsUsermanagement] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isGuidelinesandpolicies, setIsGuidelinesandpolicies] = useState(false);
  const [isAnnouncements, setIsAnnouncements] = useState(false);
  const [isTicketingsystem, setIsTicketingsystem] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isAccessRights, setIsAccessRights] = useState(false);
  const [isPackageServices, setIsPackageServices] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  const permissionData = useSelector(
    (state) => state.userDetails.userPermission
  );

  const userData = useSelector((state) => state.userDetails.userData);

  const userRole = userData?.role;

  // const hasPermission = permissionData?.find(item => item.module_name === GENERAL_SETTING);
  const checkpermission = {
    [ONLINE_ASSETS]: permissionData?.find(
      (item) => item.module_name === ONLINE_ASSETS
    ),
    [USERS]: permissionData?.find((item) => item.module_name === USERS),
    // [DEPARTMENT_USERS]: permissionData?.find(
    //   (item) => item.module_name === DEPARTMENT_USERS
    // ),
    [DEVELOPER_PROFILE]: permissionData?.find(
      (item) => item.module_name === DEVELOPER_PROFILE
    ),
    [ACCESS_RIGHTS]: permissionData?.find(
      (item) => item.module_name === ACCESS_RIGHTS
    ),
    [WEBSITE_GUIDELINE]: permissionData?.find(
      (item) => item.module_name === WEBSITE_GUIDELINE
    ),
    [BRANDING_GUIDELINE]: permissionData?.find(
      (item) => item.module_name === BRANDING_GUIDELINE
    ),
    [WEBSITE_STATISTICS]: permissionData?.find(
      (item) => item.module_name === WEBSITE_STATISTICS
    ),
    [ANNOUNCEMENTS]: permissionData?.find(
      (item) => item.module_name === ANNOUNCEMENTS
    ),
    [TICKETING_SYSTEM]: permissionData?.find(
      (item) => item.module_name === TICKETING_SYSTEM
    ),
    [UPTIME_REPORT]: permissionData?.find(
      (item) => item.module_name === UPTIME_REPORT
    ),
    [HIT_REPORT]: permissionData?.find(
      (item) => item.module_name === HIT_REPORT
    ),
    [PERFORMANCE]: permissionData?.find(
      (item) => item.module_name === PERFORMANCE
    ),
    [WEB_SECURITY]: permissionData?.find(
      (item) => item.module_name === WEB_SECURITY
    ),
  };
  // const permissions = {
  //   [GENERAL_SETTING]: permissionData?.find(
  //     (item) => item.module_name === GENERAL_SETTING
  //   ),
  //   [OTHER_SETTING]: permissionData?.find(
  //     (item) => item.module_name === OTHER_SETTING
  //   ),
  //   [SECURITY_SETTING]: permissionData?.find(
  //     (item) => item.module_name === SECURITY_SETTING
  //   ),
  //   [SEO_SETTING]: permissionData?.find(
  //     (item) => item.module_name === SEO_SETTING
  //   ),
  //   [SMTP_MAIL_SETTING]: permissionData?.find(
  //     (item) => item.module_name === SMTP_MAIL_SETTING
  //   ),
  //   [API_KEY]: permissionData?.find((item) => item.module_name === API_KEY),
  //   [WEBSITE_CATEGORIES]: permissionData?.find(
  //     (item) => item.module_name === WEBSITE_CATEGORIES
  //   ),
  // };

  // const handleTabName = (tabName) => {
  //   dispatch(setActiveTab(tabName));
  // };

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "WebsiteManagement") {
      setIsWebsitemanagement(false);
    }
    if (iscurrentState !== "Usermanagement") {
      setIsUsermanagement(false);
    }
    if (iscurrentState !== "AccessRights") {
      setIsAccessRights(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "Guidelinesandpolicies") {
      setIsGuidelinesandpolicies(false);
    }
    if (iscurrentState !== "Announcement") {
      setIsAnnouncements(false);
    }
    if (iscurrentState !== "Ticketingsystem") {
      setIsTicketingsystem(false);
    }
    if (iscurrentState !== "PackageServices") {
      setIsPackageServices(false);
    }
    if (iscurrentState !== "Reporting") {
      setIsReporting(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    iswebsitemanagement,
    isUsermanagement,
    isAccessRights,
    isPages,
    isGuidelinesandpolicies,
    isAnnouncements,
    isTicketingsystem,
    isReporting,
  ]);

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: <HomeIcon />,
      link: "/dashboard",
      stateVariables: isDashboard,

      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "website-management",
      label: "Website Management",
      icon: <LanguageIcon />,
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsWebsitemanagement(!iswebsitemanagement);
        setIscurrentState("WebsiteManagement");
        updateIconSidebar(e);
      },
      stateVariables: iswebsitemanagement,
      subItems: [
        {
          id: "online-assets",
          label: "Online Assets",
          link: "/online-assets",
          icon: <i className="ri-edit-circle-line"></i>,
        },
        // { id: "api", label: "API Connectivity", link: "/api-connectivity", },
      ],
    },
    {
      id: "user-management",
      label: "User Management",
      icon: <GroupIcon />,
      link: "/",
      click: function (e) {
        e.preventDefault();
        setIsUsermanagement(!isUsermanagement);
        setIscurrentState("Usermanagement");
        updateIconSidebar(e);
      },
      stateVariables: isUsermanagement,
      subItems: [
        {
          id: "users",
          label: "Core Users",
          link: "/core-users",
          parentId: "user-management",
        },
        // {
        //   id: "internal-user",
        //   label: "Internal Users",
        //   link: "/internal-users",
        //   parentId: "user-management",
        // },
        // {
        //   id: "users",
        //   label: "Core Users",
        //   link: "/users",
        //   parentId: "user-management",
        // },
        // {
        //   id: "internal-user",
        //   label: "Internal Users",
        //   link: "/core-users",
        //   parentId: "user-management",
        // },
        // { id: "workflow-rules", label: "Workflow Rules", link: "/workflow-rules", parentId: "user-management", },
        {
          id: "developer-profile",
          label: "Developer Profile",
          link: "/developer-profile",
          parentId: "user-management",
        },
      ],
    },
    {
      id: "access-right",
      label: "Access Right",
      icon: <SiteAltIcon />,
      link: "/",
      click: function (e) {
        e.preventDefault();
        setIsAccessRights(!isAccessRights);
        setIscurrentState("AccessRights");
        updateIconSidebar(e);
      },
      stateVariables: isAccessRights,
      subItems: [
        {
          id: "role-manager",
          label: "Role Manager",
          link: "/role-manager",
          parentId: "access-right",
        },
      ],
    },
    {
      id: "guidelines-policies",
      label: "Guidelines & Policies",
      icon: <GuidAltIcon />,
      link: "/",
      click: function (e) {
        e.preventDefault();
        setIsGuidelinesandpolicies(!isGuidelinesandpolicies);
        setIscurrentState("Guidelinesandpolicies");
        updateIconSidebar(e);
      },
      stateVariables: isGuidelinesandpolicies,
      subItems: [
        {
          id: "website-guideline",
          label: "Website Guideline",
          icon: " las la-microphone-alt",
          link: "/website-guideline",
          parentId: "guidelines-policies",
        },
        {
          id: "branding-guidelines",
          label: "Branding Guidelines",
          link: "/branding-guideline",
          parentId: "guidelines-policies",
        },
        {
          id: "website-policies",
          label: "Website Policies",
          link: "/website-policies",
          parentId: "guidelines-policies",
        },
      ],
    },
    {
      id: "announcements",
      label: "Announcements",
      icon: <CampaignRoundedIcon />,
      link: "/announcements",
      stateVariables: isAnnouncements,
      click: function (e) {
        e.preventDefault();
        setIsAnnouncements(!isAnnouncements);
        setIscurrentState("Announcement");
        updateIconSidebar(e);
      },
    },
    {
      id: "ticketing-system",
      label: "Ticketing System",
      icon: <LabelIcon />,
      link: "/ticketing-system",
      stateVariables: isTicketingsystem,
      click: function (e) {
        e.preventDefault();
        setIsTicketingsystem(!isTicketingsystem);
        setIscurrentState("Ticketingsystem");
        updateIconSidebar(e);
      },
    },
    {
      id: "reporting",
      label: "Reporting",
      icon: <FlieChartLineIcon />,
      link: "/",
      click: function (e) {
        e.preventDefault();
        setIsReporting(!isReporting);
        setIscurrentState("Reporting");
        updateIconSidebar(e);
      },
      stateVariables: isReporting,
      subItems: [
        {
          id: "uptime-report",
          label: "Uptime Report",
          link: "/uptime-report",
          parentId: "reporting",
        },
        {
          id: "hit-report",
          label: "HIT Report",
          link: "/hit-report",
          parentId: "reporting",
        },
        {
          id: "performance",
          label: "Performance",
          link: "/performance",
          parentId: "reporting",
        },
        {
          id: "websecurity",
          label: "Web Security",
          link: "/web-security",
          parentId: "reporting",
        },
      ],
    },
    {
      id: "packages-services",
      label: "Packages & Services",
      icon: <SubscriptionsIcon />,
      link: "/package",
      stateVariables: isPackageServices,
      click: function (e) {
        e.preventDefault();
        setIsPackageServices(!isPackageServices);
        setIscurrentState("PackageServices");
        updateIconSidebar(e);
      },
    },
  ];

  // const settingsMenuItems = [
  //   {
  //     id: "General",
  //     label: "General",
  //     icon: "ri-settings-3-line",
  //     link: "/settings",
  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("General");
  //     },
  //   },
  //   {
  //     id: "SMTP Mail",
  //     label: "SMTP-Mail",
  //     icon: "ri-mail-send-line",
  //     link: "/settings",

  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("SMTP Mail");
  //     },
  //   },
  //   {
  //     id: "SEO",
  //     label: "SEO",
  //     icon: "ri-search-eye-line",
  //     link: "/settings",
  //     stateVariables: false,
  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("SEO");
  //     },
  //   },
  //   {
  //     id: "Security Settings",
  //     label: "Security Settings",
  //     icon: "ri-lock-line",
  //     link: "/settings",
  //     stateVariables: false,
  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("Security Settings");
  //     },
  //   },
  //   // {
  //   //   id: "Cron Settings",
  //   //   label: "Cron settings",
  //   //   icon: "ri-time-line",
  //   //   link: "/settings",
  //   //   stateVariables: false,
  //   //   click: function (e) {
  //   //     e.preventDefault();
  //   //     handleTabName("Cron Settings")
  //   //   },

  //   // },
  //   {
  //     id: "Other Settings",
  //     label: "Other Settings",
  //     icon: "ri-list-settings-line",
  //     link: "/settings",
  //     stateVariables: false,
  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("Other Settings");
  //     },
  //   },
  //   {
  //     id: "API Key",
  //     label: "Third Party API settings",
  //     icon: "ri-key-fill",
  //     link: "/settings",
  //     stateVariables: false,
  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("API Key");
  //     },
  //   },
  //   {
  //     id: "Website Category",
  //     label: "Website Category",
  //     icon: "ri-list-settings-line",
  //     link: "/settings",
  //     stateVariables: false,
  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("Website Category");
  //     },
  //   },
  //   {
  //     id: "Skills",
  //     label: "Skills",
  //     icon: "ri-list-check",
  //     link: "/settings",
  //     stateVariables: false,
  //     click: function (e) {
  //       e.preventDefault();
  //       handleTabName("Skills");
  //     },
  //   },
  // ];

  const filtermenuItems = menuItems
    .map((item) => {
      if (item.id === "dashboard") {
        return item;
      }
      if (item.subItems) {
        const subItemsWithPermission = item.subItems.filter((subItem) => {
          switch (subItem.id) {
            case "online-assets":
              return (
                checkpermission[ONLINE_ASSETS] &&
                checkpermission[ONLINE_ASSETS]?.canList !== null
              );
            case "users":
              return (
                checkpermission[USERS] &&
                checkpermission[USERS]?.canList !== null
              );
            // case "internal-user":
            //   return (
            //     checkpermission[DEPARTMENT_USERS] &&
            //     checkpermission[DEPARTMENT_USERS]?.canList !== null
            //   );
            case "developer-profile":
              return (
                checkpermission[DEVELOPER_PROFILE] &&
                checkpermission[DEVELOPER_PROFILE]?.canList !== null
              );
            case "role-manager":
              return (
                checkpermission[ACCESS_RIGHTS] &&
                checkpermission[ACCESS_RIGHTS]?.canList !== null
              );
            case "website-guideline":
              return (
                checkpermission[WEBSITE_GUIDELINE] &&
                checkpermission[WEBSITE_GUIDELINE]?.canList !== null
              );
            case "branding-guidelines":
              return (
                checkpermission[BRANDING_GUIDELINE] &&
                checkpermission[BRANDING_GUIDELINE]?.canList !== null
              );
            case "website-policies":
              return (
                checkpermission[WEBSITE_STATISTICS] &&
                checkpermission[WEBSITE_STATISTICS]?.canList !== null
              );
            case "announcements":
              return (
                checkpermission[ANNOUNCEMENTS] &&
                checkpermission[ANNOUNCEMENTS]?.canList !== null
              );
            case "ticketing-system":
              return (
                checkpermission[TICKETING_SYSTEM] &&
                checkpermission[TICKETING_SYSTEM]?.canList !== null
              );
            case "packages-services":
              return userRole === "Super Admin";
            case "uptime-report":
              return (
                checkpermission[UPTIME_REPORT] &&
                checkpermission[UPTIME_REPORT]?.canList !== null
              );
            case "hit-report":
              return (
                checkpermission[HIT_REPORT] &&
                checkpermission[HIT_REPORT]?.canList !== null
              );
            case "performance":
              return (
                checkpermission[PERFORMANCE] &&
                checkpermission[PERFORMANCE]?.canList !== null
              );
            case "websecurity":
              return (
                checkpermission[WEB_SECURITY] &&
                checkpermission[WEB_SECURITY]?.canList !== null
              );
            default:
              return (
                checkpermission[subItem.id] &&
                checkpermission[subItem.id]?.canList !== null
              );
          }
        });
        if (subItemsWithPermission.length > 0) {
          return { ...item, subItems: subItemsWithPermission };
        }
        // If no subItem has permission, exclude the menuItem
        return null;
      } else {
        // If there are no subItems, add permission based on the id
        return (checkpermission[item.id] &&
          checkpermission[item.id]?.canList !== null) ||
          (item.id === "announcements" &&
            checkpermission[ANNOUNCEMENTS] &&
            checkpermission[ANNOUNCEMENTS]?.canList !== null) ||
          (item.id === "ticketing-system" &&
            checkpermission[TICKETING_SYSTEM] &&
            checkpermission[TICKETING_SYSTEM]?.canList !== null) ||
          (item.id === "packages-services" && userRole === "Super Admin")
          ? item
          : null;
      }
    })
    .filter(Boolean);

  // const filteredSettingsMenuItems = settingsMenuItems.filter((item) => {
  //   switch (item.id) {
  //     case "General":
  //       return (
  //         permissions[GENERAL_SETTING] &&
  //         permissions[GENERAL_SETTING]?.canList !== null
  //       );
  //     case "SMTP Mail":
  //       return (
  //         permissions[SMTP_MAIL_SETTING] &&
  //         permissions[SMTP_MAIL_SETTING]?.canList !== null
  //       );
  //     case "SEO":
  //       return (
  //         permissions[SEO_SETTING] && permissions[SEO_SETTING]?.canList !== null
  //       );
  //     case "Security Settings":
  //       return (
  //         permissions[SECURITY_SETTING] &&
  //         permissions[SECURITY_SETTING]?.canList !== null
  //       );
  //     case "Other Settings":
  //       return (
  //         permissions[OTHER_SETTING] &&
  //         permissions[OTHER_SETTING]?.canList !== null
  //       );
  //     case "API Key":
  //       return permissions[API_KEY] && permissions[API_KEY]?.canList !== null;
  //     case "Website Category":
  //       return (
  //         permissions[WEBSITE_CATEGORIES] &&
  //         permissions[WEBSITE_CATEGORIES]?.canList !== null
  //       );
  //     default:
  //       return true;
  //   }
  // });

  return (
    <React.Fragment>
      {filtermenuItems}
      {/* {path === "/settings" ? filteredSettingsMenuItems : filtermenuItems} */}
    </React.Fragment>
  );
};
export default Navdata;
