import React, { useCallback, useEffect, useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { ToastMessage } from "../../../Components/Common/ToastMessage";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_ROUTES } from "../../../Api/ApiRoutes";
import { WebSecuTabStyle } from "../../NewDashboard/constant";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import Tab from "@mui/material/Tab";
import common from "../../../Components/Common/common";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import axios from "axios";

const AddRole = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState([]);
  const [modules, setModules] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);
  const [roleName, setRoleName] = useState({
    name: "",
    // description: "",
    isAdmin: "",
  });

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedById, setIsCheckedById] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingKeepEditing, setLoadingKeepEditing] = useState(false);

  const id = params?.id;
  const userData = useSelector((state) => state.userDetails.userData);

  const getRolePermissionById = useCallback(async () => {
    try {
      if (!id) return;

      // API request to fetch role permissions by ID
      const response = await axios.post(API_ROUTES.GET_ROLES_PERMISSION, {
        id,
      });

      // Check if the API call was successful
      if (response.status === "success") {
        // Decrypt the role permissions and role name
        const decryptedPermissions = common.decrypt(response.data);
        const decryptedRoleDetails = common.decrypt(response.message);

        // Set the decrypted values in the component's state
        setRolePermission(decryptedPermissions);
        setRoleName(decryptedRoleDetails);

        // Update form field with the role name
        validation.setFieldValue("name", decryptedRoleDetails?.name);

        // Set admin status based on the decrypted role details
        setIsCheckedById(
          decryptedRoleDetails.chr_is_admin === "N" ? false : true
        );
      }
    } catch (error) {
      console.error("Error in getting the data...", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    getRolePermissionById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRolePermissionById]);

  const handlePermissionToggle = (moduleId, permissionName) => {
    const updatedModules = modules.map((module) => {
      if (module.module_id === moduleId) {
        const updatedPermissions = module.permissions.map((permission) => {
          if (permission.name === permissionName) {
            return {
              ...permission,
              selected: !permission.selected,
            };
          }
          return permission;
        });

        return {
          ...module,
          permissions: updatedPermissions,
        };
      }
      return module;
    });

    setModules(updatedModules);
  };

  const getData = useCallback(async () => {
    try {
      const reqData = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await fetch(API_ROUTES.GET_MODULE, reqData);
      if (response.status === 200) {
        const data = await response.json();

        const decryptedData = common.decrypt(data.data);
        data.data = decryptedData;
        let updatedModules = data.data;
        if (rolePermission.length > 0) {
          updatedModules = updatedModules.map((module) => {
            const updatedPermissions = module.permissions.map((permission) => {
              if (rolePermission.includes(permission.id)) {
                return {
                  ...permission,
                  selected: true,
                };
              }
              return permission;
            });

            return {
              ...module,
              permissions: updatedPermissions,
            };
          });
        }

        setModules(updatedModules);
      } else {
        console.error("Error in getting the data...");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [rolePermission]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSwitchChange = () => {
    if (!id) {
      setIsChecked(!isChecked);
    }
    if (id) {
      setIsCheckedById(!isCheckedById);
    }
  };

  const validation = useFormik({
    initialValues: {
      name: "",
      // description: "",
      chr_is_admin: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("please enter the name"),
      // description: Yup.string().required("please enter the description"),
    }),
    onSubmit: () => {
      postData();
    },
  });

  if (isChecked === true) {
    validation.values.chr_is_admin = "Y";
  } else {
    validation.values.chr_is_admin = "N";
  }

  if (isCheckedById === true) {
    roleName.isAdmin = "Y";
  } else {
    roleName.isAdmin = "N";
  }

  const postData = async (per) => {
    if (!validation.values.name.trim())
      return ToastMessage.Error("role name cannot be empty");

    // Set loading state
    if (per === "keepEditing") {
      setLoadingKeepEditing(true);
    } else {
      setLoading(true);
    }

    let userData = {
      name: id ? roleName.name : validation.values.name,
      chr_is_admin: id ? roleName.isAdmin : validation.values.chr_is_admin,
      modulePermissions: data,
    };

    const url = id ? `role/roles/${id}` : "role/roles";

    try {
      const response = await axios.post(url, userData);
      if (response?.data?.data) {
        response.data.data = common.decrypt(response.data.data);
      }

      if (per === "keepEditing") {
        if (response.status === "success") {
          if (!id) {
            navigate(`/role-manager/add/${response?.data?.data[0]?.id}`, {
              replace: true,
            });
          }
          getRolePermissionById();
          getData();
          ToastMessage.Success(
            `Role has been successfully ${id ? "updated" : "Added"}`
          );
        } else if (response.status === "fail") {
          ToastMessage.Error(response.message);
        }
      } else {
        if (response.status === "success") {
          getRolePermissionById();
          getData();
          ToastMessage.Success(
            `Role has been successfully ${id ? "updated" : "Added"}`
          );
          setTimeout(() => {
            navigate("/role-manager");
          }, 500);
        } else if (response.status === "fail") {
          ToastMessage.Error(response.message);
        }
      }
    } catch (error) {
      ToastMessage.Error(error?.message || "An error occurred");
    } finally {
      setLoading(false);
      setLoadingKeepEditing(false);
    }
  };

  const handleSelectAllToggle = (moduleId) => {
    const updatedModules = modules.map((module) => {
      if (module.module_id === moduleId) {
        const updatedPermissions = module.permissions.map((permission) => ({
          ...permission,
          selected: !module.permissions.every((p) => p.selected),
        }));

        return {
          ...module,
          permissions: updatedPermissions,
        };
      }
      return module;
    });
    setModules(updatedModules);
  };

  const generateData = useCallback(() => {
    const selectedModules = modules.filter((module) =>
      module.permissions.some((permission) => permission.selected)
    );

    const modulePermissions = selectedModules.map((selectedModule) => ({
      name: selectedModule.module_name,
      canList:
        selectedModule.permissions.find(
          (permission) => permission.name === "list" && permission.selected
        )?.id || "",
      canCreate:
        selectedModule.permissions.find(
          (permission) => permission.name === "create" && permission.selected
        )?.id || "",
      canModify:
        selectedModule.permissions.find(
          (permission) => permission.name === "modify" && permission.selected
        )?.id || "",
      canDelete:
        selectedModule.permissions.find(
          (permission) => permission.name === "delete" && permission.selected
        )?.id || "",
    }));

    setData(modulePermissions);
  }, [modules]);

  useEffect(() => {
    generateData();
  }, [generateData]);

  const [valuerole, setValuerole] = useState("1");

  const handleChangeTabsRole = (event, newValue) => {
    setValuerole(newValue);
  };

  return (
    <Box className="add-role-page">
      <ToastContainer />

      <Form>
        <Row className="g-3 mb-4 align-items-center">
          <Col md={6}>
            {!id ? (
              <>
                {/* <Label>Name *</Label> */}
                {/* <Input
                  label="name"
                  required
                  name="name"
                  fullwidth="true"
                  bsSize="small"
                  id="outlined-size-small"
                  className="border-0"
                  placeholder="Name"
                  value={validation.values.name || ""}
                  onChange={validation.handleChange}
                /> */}
                <TextField
                  fullWidth
                  label="Name *"
                  name="name"
                  id="outlined-size-small"
                  placeholder="Name"
                  value={validation.values.name || ""}
                  onChange={validation.handleChange}
                />
              </>
            ) : (
              <>
                {/* <Label>Name *</Label> */}
                {/* <Input
                  name="name"
                  required
                  fullwidth="true"
                  bsSize="small"
                  id="outlined-size-small"
                  className="border-0"
                  placeholder="Name"
                  value={roleName?.name || ""}
                  onChange={(e) =>
                    setRoleName({ ...roleName, name: e.target.value })
                  }
                /> */}
                <TextField
                  fullWidth
                  label="Name*"
                  name="name"
                  id="outlined-size-small"
                  placeholder="Name"
                  value={roleName?.name || ""}
                  onChange={(e) =>
                    setRoleName({ ...roleName, name: e.target.value })
                  }
                />
              </>
            )}
          </Col>
          {/* <Col md={6}>
            {!id ? (
              <>
                <Label>Description *</Label>
                <Input
                  label="Description"
                  name="description"
                  fullwidth="true"
                  bsSize="small"
                  id="outlined-size-small"
                  className="border-0"
                  value={validation.values.description || ""}
                  onChange={validation.handleChange}
                />
              </>
            ) : (
              <>
                <Label>Description *</Label>
                <Input
                  name="description"
                  fullwidth="true"
                  bsSize="small"
                  id="outlined-size-small"
                  className="border-0"
                  value={roleName?.description || ""}
                  onChange={(e) =>
                    setRoleName({
                      ...roleName,
                      description: e.target.value,
                    })
                  }
                />
              </>
            )}
          </Col> */}
          <Col md={6} sm={12}>
            <Box className="d-flex justify-content-end fs-16">
              <strong>Is Admin Role ? </strong>
              <span style={{ paddingLeft: "10px" }}>
                {!id ? (
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={isChecked}
                    onChange={handleSwitchChange}
                  />
                ) : (
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={isCheckedById}
                    onChange={handleSwitchChange}
                  />
                )}
              </span>
            </Box>
          </Col>
        </Row>
      </Form>
      <Card>
        <TabContext value={valuerole}>
          <TabList
            onChange={handleChangeTabsRole}
            variant="fullWidth"
            sx={WebSecuTabStyle}
          >
            <Tab label="General" value="1" />
            {/* <Tab label="Other" value="2" /> */}
            {["Super Admin", "Admin"].includes(userData?.role) && (
              <Tab label="Other" value="2" />
            )}
          </TabList>
          {modules.filter((a) => a.category === "General") && (
            <TabPanel value="1" className="tab-panel">
              <Box className="table-responsive table-design">
                <table className="table table-hover table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Module &amp; Access</th>
                      <th scope="col" className="text-center" style={{ width: "110px" }}>
                        All{" "}
                      </th>
                      <th scope="col" className="text-center" style={{ width: "110px" }}>
                        List
                      </th>
                      <th scope="col" className="text-center" style={{ width: "110px" }}>
                        Create
                      </th>
                      <th scope="col" className="text-center" style={{ width: "110px" }}>
                        Modify
                      </th>
                      <th scope="col" className="text-center" style={{ width: "110px" }}>
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!id
                      ? modules
                          .filter((a) => a.category === "General")
                          .map((module) => (
                            <tr key={module.module_id}>
                              <td>{module.module_name}</td>
                              <td className="text-center">
                                <Box>
                                  <input
                                    checked={module.permissions.every(
                                      (p) => p.selected
                                    )}
                                    onChange={() =>
                                      handleSelectAllToggle(module.module_id)
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </Box>
                              </td>
                              {["list", "create", "modify", "delete"].map(
                                (permissionName) => {
                                  const permission = module.permissions.find(
                                    (p) => p.name === permissionName
                                  );

                                  return (
                                    <td
                                      key={permissionName}
                                      className="text-center"
                                    >
                                      <Box>
                                        {permission ? (
                                          <input
                                            checked={permission.selected}
                                            onChange={() =>
                                              handlePermissionToggle(
                                                module.module_id,
                                                permission.name
                                              )
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                          />
                                        ) : (
                                          <> - </>
                                        )}
                                      </Box>
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          ))
                      : modules
                          .filter((a) => a.category === "General")
                          .map((module) => (
                            <tr key={module.module_id}>
                              <td>{module.module_name}</td>
                              <td className="text-center">
                                <Box>
                                  <input
                                    checked={module.permissions.every(
                                      (p) => p.selected
                                    )}
                                    onChange={() =>
                                      handleSelectAllToggle(module.module_id)
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </Box>
                              </td>
                              {["list", "create", "modify", "delete"].map(
                                (permissionName) => {
                                  const permission = module.permissions.find(
                                    (p) => p.name === permissionName
                                  );

                                  return (
                                    <td
                                      key={permissionName}
                                      className="text-center"
                                    >
                                      <Box>
                                        {permission ? (
                                          <input
                                            checked={permission.selected}
                                            onChange={() =>
                                              handlePermissionToggle(
                                                module.module_id,
                                                permission.name
                                              )
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                          />
                                        ) : (
                                          <> - </>
                                        )}
                                      </Box>
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          ))}
                  </tbody>
                </table>
              </Box>
            </TabPanel>
          )}
          {modules.filter((a) => a.category === "Other") && (
            <TabPanel value="2" className="tab-panel">
              <Box className="table-responsive table-design">
                <table className="table table-hover table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Module &amp; Access</th>
                      <th style={{ width: "40px" }}>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!id
                      ? modules
                          .filter((a) => a.category === "Other")
                          .map((module) => (
                            <tr key={module.module_id}>
                              <td>{module.module_name}</td>
                              <td>
                                <Box>
                                  <input
                                    checked={module.permissions.every(
                                      (p) => p.selected
                                    )}
                                    onChange={() =>
                                      handleSelectAllToggle(module.module_id)
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </Box>
                              </td>
                              {[].map((permissionName) => {
                                const permission = module.permissions.find(
                                  (p) => p.name === permissionName
                                );

                                return (
                                  <td key={permissionName}>
                                    <Box>
                                      {permission ? (
                                        <input
                                          checked={permission.selected}
                                          onChange={() =>
                                            handlePermissionToggle(
                                              module.module_id,
                                              permission.name
                                            )
                                          }
                                          className="form-check-input"
                                          type="checkbox"
                                        />
                                      ) : (
                                        <> - </>
                                      )}
                                    </Box>
                                  </td>
                                );
                              })}
                            </tr>
                          ))
                      : modules
                          .filter((a) => a.category === "Other")
                          .map((module) => (
                            <tr key={module.module_id}>
                              <td>{module.module_name}</td>
                              <td>
                                <Box>
                                  <input
                                    checked={module.permissions.every(
                                      (p) => p.selected
                                    )}
                                    onChange={() =>
                                      handleSelectAllToggle(module.module_id)
                                    }
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </Box>
                              </td>
                              {[].map((permissionName) => {
                                const permission = module.permissions.find(
                                  (p) => p.name === permissionName
                                );

                                return (
                                  <td key={permissionName}>
                                    <Box>
                                      {permission ? (
                                        <input
                                          checked={permission.selected}
                                          onChange={() =>
                                            handlePermissionToggle(
                                              module.module_id,
                                              permission.name
                                            )
                                          }
                                          className="form-check-input"
                                          type="checkbox"
                                        />
                                      ) : (
                                        <> - </>
                                      )}
                                    </Box>
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                  </tbody>
                </table>
              </Box>
            </TabPanel>
          )}
        </TabContext>
      </Card>
      <Card.Footer className="border-0 mt-3">
        <Box className="d-flex flex-wrap justify-content-end">
          <Button
            onClick={() => postData("keepEditing")}
            className="btn btn-warning me-2 mb-2 flex-grow-1 flex-sm-grow-0"
            // disabled={loadingKeepEditing}
          >
            Save & Keep Editing
            {/* {loadingKeepEditing ? (
              <Spinner size="sm" variant="light" />
            ) : (
              "Save & Keep Editing"
            )} */}
          </Button>
          <Button
            onClick={() => postData()}
            className="btn btn-success me-2 mb-2 flex-grow-1 flex-sm-grow-0"
            disabled={loading}
          >
            {loading ? <Spinner size="sm" variant="light" /> : "Save & Exit"}
          </Button>
          {id ? (
            <Button
              onClick={() => navigate("/role-manager")}
              className="btn btn-light me-2 mb-2 flex-grow-1 flex-sm-grow-0"
            >
              Cancel
            </Button>
          ) : (
            <Button
              onClick={() => navigate("/role-manager")}
              className="btn btn-light me-2 mb-2 flex-grow-1 flex-sm-grow-0"
            >
              Cancel
            </Button>
          )}
        </Box>
      </Card.Footer>
    </Box>
  );
};

export default AddRole;
