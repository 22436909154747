// import common from "../../../Components/Common/common";
import { API_ROUTES } from "../../ApiRoutes";

export const changeInternalUserStatusReq = (id, newStatus, email) => {
  let userData = {
    status: newStatus,
    email: email,
  };
  let url = API_ROUTES.UPDATE_DEPARTMENT_USERS_STATUS + id;
  let reqData = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(userData),
  };

  return { url: url, reqData };
};

export const updateInternalUsersReq = (
  values,
  id,
  assetUrlManagement,
  hiddenPassword
) => {
  let data = new FormData();

  if (values.departmentImage && values.departmentImage.constructor === File) {
    data.append("departmentImage", values.departmentImage);
  }
  if (values.password) {
    data.append("password", values.password);
  }
  data.append("user_name", values.fullName);
  data.append("email", values.email);
  data.append("contact_no", values.phone);
  data.append("country", values.country);
  data.append("role", values.roleSelect);
  if (values.departmentSelect) {
    data.append("department_id", values.departmentSelect);
  }
  data.append("parent_id", values.adminSelectOpt);
  data.append("package_id", values.packageSelectOpt);
  data.append("billing_cycle", values.selectedPriceType);
  data.append("status", values.statusOption);
  data.append("asset_url_management", JSON.stringify(assetUrlManagement));

  let url = id
    ? API_ROUTES.UPDATE_DEPARTMENT_USERS + id
    : API_ROUTES.ADD_DEPARTMENT_USERS;

  const reqData = {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  return { url: url, reqData };
};

export const DeleteDepartment = (id, userId) => {
  let url = API_ROUTES.DELETE_DEPARTMENT_USERS + id;

  const reqData = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: userId,
  };

  return { url: url, reqData };
};

export const getInternalUsersReq = (props) => {
  const { params, token } = props;
  let data = "";
  let config = {
    method: "GET",
    url:
      API_ROUTES.GET_DEPARTMENT_USERS +
      `?searchParams=${params.searchParams}&departmentNumber=${params.departmentNumber}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return { config };
};

export const getUserTypeReq = (props) => {
  const { token } = props;
  let data = "";
  let config = {
    method: "GET",
    url: API_ROUTES.GET_ROLES,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  return { config };
};
